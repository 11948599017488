import { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import styles from "./Epg.module.css";

function Epg() {
  const [programs, setPrograms] = useState([]);
  const [timeZone] = useState(DateTime.local().zoneName);
  const [selectedDate, setSelectedDate] = useState(
    DateTime.local().toFormat("yyyy-LL-dd")
  );
  const [, setIsDifferentTimeZone] = useState(false);
  const [currentTime, setCurrentTime] = useState(
    DateTime.local().setZone("Europe/Warsaw")
  );
  const currentProgramRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [lastScrolledProgram, setLastScrolledProgram] = useState(null);

  // Fetch program data for the selected date and the previous day to handle programs overlapping midnight
  const fetchData = async (date) => {
    try {
      setIsLoading(true);
      const currentDate = DateTime.fromISO(date);
      const previousDate = currentDate
        .minus({ days: 1 })
        .toFormat("yyyy-LL-dd");

      // Should be stored in .env file
      const token = "85f663b5-cc99-4268-90a6-3096e363aa8d";

      const responses = await Promise.all([
        fetch(
          `https://app.mediatool.tv/api/epg-api/information/channel/tn2p/day/${previousDate}?token=${token}`
        ),
        fetch(
          `https://app.mediatool.tv/api/epg-api/information/channel/tn2p/day/${date}?token=${token}`
        ),
      ]);

      const [previousDayData, currentDayData] = await Promise.all(
        responses.map((response) => response.json())
      );

      const programsFromBothDays = [
        ...previousDayData.schedule,
        ...currentDayData.schedule,
      ];

      // Format programs to handle time zones and calculate duration
      const formattedPrograms = programsFromBothDays.map((program) => {
        const startTime = DateTime.fromISO(program.startTime, {
          zone: "Europe/Warsaw",
        }).setZone(timeZone);
        const endTime = DateTime.fromISO(program.endTime, {
          zone: "Europe/Warsaw",
        }).setZone(timeZone);
        const duration = endTime.diff(startTime, "minutes").minutes;

        return {
          ...program,
          startTime,
          endTime,
          duration,
          seasonNumber: program.seasonNumber || 1,
          episodeNumber: program.episodeNumber || 1,
        };
      });

      const filteredPrograms = formattedPrograms.filter((program) => {
        const programDate = program.startTime
          .setZone(timeZone)
          .toFormat("yyyy-LL-dd");
        return programDate === date;
      });

      setPrograms(filteredPrograms);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, timeZone]);

  // Automatically scroll to the current program if it is airing
  useEffect(() => {
    if (currentProgramRef.current) {
      const now = DateTime.local().setZone(timeZone);
      const currentProgram = programs.find(
        (program) => now >= program.startTime && now <= program.endTime
      );

      if (currentProgram && currentProgram !== lastScrolledProgram) {
        setLastScrolledProgram(currentProgram);
        if (currentProgramRef.current && scrollContainerRef.current) {
          const containerWidth = scrollContainerRef.current.offsetWidth;
          const programWidth = currentProgramRef.current.offsetWidth;
          const programOffsetLeft = currentProgramRef.current.offsetLeft;

          const scrollTo =
            programOffsetLeft - containerWidth / 2 + programWidth / 2;

          scrollContainerRef.current.scrollTo({
            left: scrollTo,
            behavior: "smooth",
          });
        }
      }
    }
  }, [programs, currentTime, timeZone, lastScrolledProgram, isLoading]);

  useEffect(() => {
    const userTimeZone = DateTime.local().zoneName;
    setIsDifferentTimeZone(userTimeZone !== "Europe/Warsaw");
  }, []);

  // Update current time every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(DateTime.local().setZone("Europe/Warsaw"));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Handle changing the selected date
  const handleDateChange = (index) => {
    const newDate = dates[index].toFormat("yyyy-LL-dd");
    if (newDate !== selectedDate) {
      setIsLoading(true);
      setCurrentIndex(index);
      setSelectedDate(newDate);
    }
  };

  const handlePrevDate = () => {
    if (currentIndex > 0) {
      handleDateChange(currentIndex - 1);
    }
  };

  const handleNextDate = () => {
    if (currentIndex < dates.length - 1) {
      handleDateChange(currentIndex + 1);
    }
  };

  const isCurrentlyAiring = (startTime, endTime) => {
    if (!isToday) return false;
    const now = DateTime.local().setZone("Europe/Warsaw");
    return now >= startTime && now <= endTime;
  };

  // Generate timeline hours for the program
  const generateTimelineHours = () => {
    const hours = [];
    const startOfDay = DateTime.fromISO(selectedDate)
      .startOf("day")
      .setZone("Europe/Warsaw");

    const userTimeZoneOffset = DateTime.local().offset / 60;
    const warsawTimeZoneOffset =
      DateTime.local().setZone("Europe/Warsaw").offset / 60;
    const offset = userTimeZoneOffset - warsawTimeZoneOffset;

    for (let i = 0; i < 48; i++) {
      const adjustedTime = startOfDay
        .plus({ minutes: i * 30 })
        .plus({ hours: offset })
        .toFormat("HH:mm");
      hours.push(
        <div key={i} className={styles.timelineHour}>
          {adjustedTime}
        </div>
      );
    }
    hours.push(
      <div
        key={48}
        className={styles.timelineHour}
        style={{
          width: 0,
          paddingRight: "1.7rem",
          position: "absolute",
          right: 0,
        }}
      >
        00:00
      </div>
    );

    return hours;
  };

  const calculateCurrentTimePosition = () => {
    const startOfDay = DateTime.fromISO(selectedDate)
      .startOf("day")
      .setZone("Europe/Warsaw");
    const minutesSinceStartOfDay = currentTime.diff(
      startOfDay,
      "minutes"
    ).minutes;
    const position = (minutesSinceStartOfDay / (24 * 60)) * 100;
    return position;
  };

  const isToday =
    selectedDate ===
    DateTime.local().setZone("Europe/Warsaw").toFormat("yyyy-LL-dd");
  const today = DateTime.local().setZone("Europe/Warsaw");
  const startDate = today.minus({ days: 7 });

  const dates = [];
  for (let i = 0; i <= 14; i++) {
    const date = startDate.plus({ days: i });
    dates.push(date);
  }

  const renderHeaderDates = () => {
    let datesToShow = [];
    if (currentIndex === 0) {
      datesToShow = [dates[0], dates[1], dates[2]];
    } else if (currentIndex === dates.length - 1) {
      datesToShow = [
        dates[dates.length - 3],
        dates[dates.length - 2],
        dates[dates.length - 1],
      ];
    } else {
      datesToShow = [
        dates[currentIndex - 1],
        dates[currentIndex],
        dates[currentIndex + 1],
      ];
    }

    return datesToShow.map((date, index) => (
      <div
        key={index}
        className={`${styles.epgHeaderDate} ${
          date.toFormat("yyyy-LL-dd") === selectedDate ? styles.selected : ""
        }`}
        onClick={() =>
          handleDateChange(
            dates.findIndex(
              (d) => d.toFormat("yyyy-LL-dd") === date.toFormat("yyyy-LL-dd")
            )
          )
        }
      >
        <div>
          {date.toFormat("yyyy-LL-dd") === today.toFormat("yyyy-LL-dd")
            ? "Dziś"
            : date.setLocale("pl").toFormat("cccc")}
        </div>
        <div>{date.toFormat("dd.LL.yyyy")}</div>
      </div>
    ));
  };

  // const handleProgramClick = (program) => {
  //   console.log(program);
  // }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.epgHeaderTitle}>
          <a
            href="https://www.tbnpolska.tv/program-tv-dzisiaj"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <h3 style={{ fontWeight: 400 }}>Pełny program</h3>
          </a>
        </div>
        <div className={styles.epgHeader}>
          <div className={styles.arrow} onClick={handlePrevDate}>
            <svg
              className={styles.arrowSvg}
              width="19"
              height="31"
              viewBox="0 0 19 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={styles.arrowPath}
                d="M18 30L2 15.0421L18 1"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </div>
          {renderHeaderDates()}
          <div className={styles.arrow} onClick={handleNextDate}>
            <svg
              className={styles.arrowSvg}
              width="19"
              height="31"
              viewBox="0 0 19 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={styles.arrowPath}
                d="M1 1L17 15.9579L1 30"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className={styles.epgContainer} ref={scrollContainerRef}>
        <div className={styles.epgTimeline}>
          {generateTimelineHours()}
          {isToday && (
            <div
              className={styles.currentTimeLine}
              style={{ left: `${calculateCurrentTimePosition()}%` }}
            />
          )}
        </div>

        <div className={styles.programContainer}>
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <div className={styles.loader}>
                <div className={styles.circle}></div>
                <div className={styles.circle}></div>
                <div className={styles.circle}></div>
              </div>
            </div>
          ) : (
            programs.map((program, index) => {
              const isAiring = isCurrentlyAiring(
                program.startTime,
                program.endTime
              );
              const duration = program.duration;
              return (
                <div
                  key={index}
                  className={`${styles.program} ${
                    isAiring ? styles.currentlyAiring : ""
                  }`}
                  ref={isAiring ? currentProgramRef : null}
                  style={{
                    width: `${duration * 1.3 - 0.5}rem`,
                    left: `${
                      (program.startTime.diff(
                        DateTime.fromISO(selectedDate)
                          .startOf("day")
                          .setZone(timeZone),
                        "minutes"
                      ).minutes /
                        (24 * 60)) *
                      100
                    }%`,
                  }}
                  // onClick={() => handleProgramClick(program)}
                >
                  <div className={styles.programInfo}>
                    <h2 className={styles.programTitle}>
                      {program.title} (sezon {program.seasonNumber}, odc.{" "}
                      {program.episodeNumber})
                    </h2>
                    <p className={styles.programTime}>
                      {program.startTime.toFormat("HH:mm")} -{" "}
                      {program.endTime.toFormat("HH:mm")}
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default Epg;
