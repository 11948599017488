import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/core";
import axios from "axios"; // For making API requests
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import styles from "./editLiveHls.module.css";

const cookies = new Cookies();
function EditLiveHls(props) {
  const [url, setUrl] = useState("");

  const token = cookies.get("TOKEN");

  const toast = useToast();

  const handleUrlChange = (event) => setUrl(event.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/edit-live-hls`,
        { url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast({
        title: response.data.message,
        description:
          "You've succesfully edit your main hls link on live player.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  useEffect(() => {
    const getHls = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get-live-hls`
        );

        setUrl(response.data.hls);
      } catch (err) {
        console.log(err);
      }
    };

    getHls();
  }, []);

  return (
    <div className={styles.main}>
      <Link className={styles.gobacklink} to="/admin">
        {"<--- Wróć"}
      </Link>
      <form onSubmit={handleSubmit} className={styles.form}>
        <Box spacing={4} align="stretch">
          <FormControl>
            <FormLabel color="#fff" htmlFor="hls">
              GŁÓWNY LIVE HLS:
            </FormLabel>
            <Input id="hls" value={url} onChange={handleUrlChange} />
          </FormControl>
        </Box>
        <Button isFullWidth mt={3} type="submit" variantColor="blue">
          Save Edit
        </Button>
      </form>
    </div>
  );
}

export default EditLiveHls;
