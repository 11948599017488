import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../Footer/Footer";
import Player from "../Player/Player";
import styles from "./home.module.css";
import axios from "axios";
import "videojs-quality-hls-selector";
import poster from "../../assets/poster.jpg";
import posterMobile from "../../assets/poster-mobile.jpg";
import { useSelector } from "react-redux";
import { loginUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { Spinner } from "@chakra-ui/core";
import venice from "../../assets/venice.mp4";
import Vod from "../Vod/vod";
import Epg from "../Epg/Epg";

const cookies = new Cookies();

const Home = () => {
  const [adSrc, setAdSrc] = useState("");
  const [liveHls, setLiveHls] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const playerRef = useRef(null);

  const { user, isUserLoading } = useSelector((state) => state.user);

  const token = cookies.get("TOKEN");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const [adResponse, liveHlsResponse, userResponse] = await Promise.all(
            [
              axios.get(`${process.env.REACT_APP_API_URL}/get-ad`),
              axios.get(`${process.env.REACT_APP_API_URL}/get-live-hls`),
              axios.get(`${process.env.REACT_APP_API_URL}/getUser`, {
                headers: { Authorization: `Bearer ${token}` },
              }),
            ]
          );
          setAdSrc(adResponse.data.hls);
          setLiveHls(liveHlsResponse.data.hls);
          if (userResponse.data) {
            dispatch(loginUser(userResponse.data));
          }
        }
        if (!token) {
          const [adResponse, liveHlsResponse] = await Promise.all([
            axios.get(`${process.env.REACT_APP_API_URL}/get-ad`),
            axios.get(`${process.env.REACT_APP_API_URL}/get-live-hls`),
          ]);
          setAdSrc(adResponse.data.hls);
          setLiveHls(liveHlsResponse.data.hls);
        }
      } catch (err) {
        console.error(err);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: window.innerWidth >= 960 ? "16:7" : "16:9",
    poster: window.innerWidth >= 960 ? poster : posterMobile,
    language: "PL",
    playsinline: true,
    notSupportedMessage:
      "Przepraszamy, nie można odtworzyć tego źródła multimedialnego.",
    sources: [
      {
        src: venice,
        type: "video/mp4",
      },
    ],
    html5: {
      vhs: {
        overrideNative: true,
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
  };

  if (isLoading) {
    return (
      <>
        <Navbar />
        <div className={styles.spinner}>
          <Spinner thickness="4px" color="#d71961" size="xl" />
        </div>
      </>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.main}>
      <Navbar />
      {!isLoading && !isUserLoading && (
        <>
          <div className={styles.player}>
            <Player
              options={videoJsOptions}
              onReady={handlePlayerReady}
              adSrc={adSrc}
              liveHls={liveHls}
              user={user}
            />
          </div>
          <Epg />
          <Vod />
        </>
      )}

      <Footer />
    </div>
  );
};

export default Home;
