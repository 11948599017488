import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, information) {
    console.error("Error Boundary Caught Error:", error, information);
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1 style={{ color: "#fff", textAlign: "center", marginTop: "100px" }}>
          Ooops, coś poszło nie tak... Spróbuj ponownie chwilę później{" "}
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
