import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useToast } from "@chakra-ui/core";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const BASE_URL = process.env.REACT_APP_API_URL;

function ConfirmationPage() {
  const { paymentId } = useParams();
  const token = cookies.get("TOKEN");
  let history = useHistory();
  const toast = useToast();

  const fetchPaymentData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/payment/${paymentId}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching payment status:", error);
      throw error;
    }
  };

  const fetchUserData = async () => {
    try {
      const userResponse = await axios.get(`${BASE_URL}/getUser`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return userResponse.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  };

  const updateSubscription = async (user, paymentData) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/user/change-to-premium-plan`,
        {
          user,
          customer: paymentData.customer,
          subscription: paymentData.subscription,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("User subscription updated successfully:", res.data);
    } catch (error) {
      console.error("Error updating subscription:", error);
      throw error;
    }
  };

  useEffect(() => {
    const handleConfirmation = async () => {
      try {
        const paymentData = await fetchPaymentData();
        if (paymentData.payment_status === "paid") {
          const userData = await fetchUserData();
          if (userData) {
            await updateSubscription(userData, paymentData);
            toast({
              title: "Dziękuję za subskrypcję",
              description: "Teraz masz dostęp Premium do wszyskich treści VOD.",
              status: "success",
              duration: 6000,
              isClosable: true,
            });
            history.push("/");
          }
        }
      } catch (error) {
        console.error("Error handling confirmation:", error);
      }
    };

    handleConfirmation();
  }, [paymentId]); // Add dependencies to useEffect

  return <div style={{ fontSize: "100px", color: "#fff" }}></div>;
}

export default ConfirmationPage;
