import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  videoId: "",
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setVideo(state, action) {
      state.videoId = action.payload;
    },
  },
});

export const { setVideo } = videoSlice.actions;
export default videoSlice.reducer;
