import React, { useState, useEffect } from "react";
import {
  Input,
  FormControl,
  FormLabel,
  Button,
  Box,
  Text,
  Badge,
  Stack,
  IconButton,
  Checkbox,
} from "@chakra-ui/core";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import styles from "./photoUploader.module.css";
import axios from "axios"; // For making API requests

const cookies = new Cookies();

const TextInput = ({ id, label, value, onChange }) => (
  <FormControl mb={3}>
    <FormLabel color="#fff" htmlFor={id}>
      {label}
    </FormLabel>
    <Input id={id} value={value} onChange={onChange} />
  </FormControl>
);

const CategoryInput = ({
  categoryInput,
  handleCategoryChange,
  handleCategoryAdd,
  categoryArray,
  handleCategoryDelete,
}) => (
  <FormControl mb={5}>
    <FormLabel color="#fff" htmlFor="category">
      Kategorii (możesz dodać więcej niz 1)
    </FormLabel>
    <Stack isInline>
      {categoryArray.map((c, index) => (
        <Badge fontSize="16px" mr={1} mb={1} key={index} variantColor="green">
          {c}{" "}
          <IconButton
            onClick={() => handleCategoryDelete(c)}
            size="sm"
            icon="delete"
            variantColor="red"
          />
        </Badge>
      ))}
    </Stack>

    <Input
      id="category"
      value={categoryInput}
      onChange={handleCategoryChange}
    />
    <Button mt={3} onClick={handleCategoryAdd} variantColor="blue">
      Add Category
    </Button>
  </FormControl>
);

const EpisodeInput = ({
  episodeInput,
  handleEpisodeChange,
  handleEpisodeAdd,
  episodesArray,
  handleEpisodeDelete,
  isEpisodeDateEnabled,
  handleEpisodeTimeCheckboxChange,
}) => (
  <FormControl mb={5}>
    <FormLabel color="#fff" htmlFor="category">
      Dodaj Odcinek
    </FormLabel>
    <Stack>
      {episodesArray.map((episode, index) => (
        <div key={index} className={styles.episode}>
          <h2 style={{ fontSize: "25px" }}>Odcinek: {episode.episodeNumber}</h2>
          <p style={{ fontSize: "10px" }}>{episode.hls}</p>
          {episode.availableDate && (
            <div>
              Dostępny od {new Date(episode.availableDate).toLocaleString()}
            </div>
          )}
          <IconButton
            onClick={() => handleEpisodeDelete(episode.episodeNumber)}
            size="sm"
            icon="delete"
            variantColor="red"
          />
        </div>
      ))}
    </Stack>

    <Input
      id="episode"
      name="episodeNumber"
      type="number"
      value={episodeInput.episodeNumber}
      onChange={handleEpisodeChange}
      placeholder="Numer Odcinka"
    />
    <Input
      id="episode"
      name="hls"
      value={episodeInput.hls}
      onChange={handleEpisodeChange}
      placeholder="HLS URL"
    />
    <Checkbox
      isChecked={isEpisodeDateEnabled}
      onChange={handleEpisodeTimeCheckboxChange}
      mb={2}
      color="#fff"
    >
      Ustaw datę dostępności odcinka (Domyślnie jest od razu)
    </Checkbox>

    {isEpisodeDateEnabled && (
      <Input
        id="availableDate"
        name="availableDate"
        type="datetime-local"
        value={episodeInput.availableDate}
        onChange={handleEpisodeChange}
        placeholder="Data dostępności odcinka"
        mb={2}
      />
    )}
    <div>
      <Button mt={3} onClick={handleEpisodeAdd} variantColor="blue">
        Dodaj odcinek
      </Button>
    </div>
  </FormControl>
);

function PhotoUploader() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [hlsUrl, setHlsUrl] = useState("");
  const [categoryInput, setCategoryInput] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  const [episodeInput, setEpisodeInput] = useState({
    episodeNumber: "",
    hls: "",
    availableDate: "",
  });
  const [episodesArray, setEpisodesArray] = useState([]);
  const [availableMovieDate, setAvailableMovieDate] = useState("");
  const [uploadResponse, setUploadResponse] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isEpisodeDateEnabled, setIsEpisodeDateEnabled] = useState(false);
  const [isMovieDateEnabled, setIsMovieDateEnabled] = useState(false);

  const token = cookies.get("TOKEN");
  const handleTitleChange = (event) => setTitle(event.target.value);
  const handleDescChange = (event) => setDesc(event.target.value);
  const handleHlsUrlChange = (event) => setHlsUrl(event.target.value);
  const handleFileChange = (event) => setSelectedFile(event.target.files[0]);
  const handleCategoryChange = (event) => setCategoryInput(event.target.value);
  const handleCategoryAdd = () => {
    if (categoryInput.trim() !== "") {
      setCategoryArray((prevCategories) => [
        ...prevCategories,
        categoryInput.toLowerCase(),
      ]);
      setCategoryInput("");
    }
  };
  const handleCategoryDelete = (c) => {
    setCategoryArray((prevCategories) =>
      prevCategories.filter((category) => category !== c)
    );
  };

  const handleEpisodeChange = (event) => {
    if (event.target.name === "episodeNumber") {
      setEpisodeInput({
        ...episodeInput,
        episodeNumber: !event.target.value ? "" : Number(event.target.value),
      });
      return;
    }
    setEpisodeInput({
      ...episodeInput,
      [event.target.name]: event.target.value,
    });
  };
  const handleEpisodeAdd = () => {
    if (!episodeInput.hls || !episodeInput.episodeNumber) {
      return alert("Ooopss nie wpisałeś HLS lub numer odcinka");
    }
    if (
      episodesArray.find(
        (epis) => epis.episodeNumber === episodeInput.episodeNumber
      )
    ) {
      return alert(
        "Ooopss wpisałeś numer odcinka który juz masz wpisany wcześniej"
      );
    }
    if (episodeInput.episodeNumber <= 1) {
      return alert(
        "Nie mozesz dodac odcinek NUMER 1 lub mniej w tym polu. Zeby dodac odcinek nr.1 lub film zobacz pole wyzej które jest podpisane 'HLS Odcinka nr 1 albo Film'"
      );
    }
    if (episodeInput.episodeNumber > 1) {
      setEpisodesArray((prevEpisodes) => [...prevEpisodes, episodeInput]);
      setEpisodeInput({
        episodeNumber: "",
        hls: "",
        availableDate: "",
      });
      setIsEpisodeDateEnabled(false);
    }
  };
  const handleEpisodeTimeCheckboxChange = (e) => {
    setIsEpisodeDateEnabled(e.target.checked);
    if (!e.target.checked) {
      handleEpisodeChange({ target: { name: "availableDate", value: "" } }); // Clear the date if checkbox is unchecked
    }
  };
  const handleMovieTimeCheckboxChange = (e) => {
    setIsMovieDateEnabled(e.target.checked);
    if (!e.target.checked) {
      setAvailableMovieDate(""); // Clear the date if checkbox is unchecked
    }
  };
  const handleEpisodeDelete = (eNum) => {
    setEpisodesArray((prevEpisodes) =>
      prevEpisodes.filter((episode) => episode.episodeNumber !== eNum)
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (!title) {
      return alert("Ooops, zapomniałeś wpisać Tytuł");
    }
    if (!desc) {
      return alert("Ooops, zapomniałeś wpisać Opis");
    }
    if (!hlsUrl) {
      return alert("Ooops, zapomniałeś wpisać HLS Odcinka nr 1 albo Film");
    }
    if (isMovieDateEnabled && !availableMovieDate) {
      return alert(
        "Ooops, włączyłeś planowanie publikacji ale zapomniałeś wpisać datę i czas Premiery, Proszę wpisz datę lub wyłącz planowania publikacji"
      );
    }
    if (categoryArray.length === 0) {
      return alert("Ooops, zapomniałeś wpisać Kategorii");
    }
    if (!selectedFile) {
      return alert("Ooops, zapomniałeś wstawić zdjęcie");
    }

    const formData = new FormData();
    formData.append("photo", selectedFile); // Key should match server-side expectation
    formData.append("title", title);
    formData.append("desc", desc);
    formData.append("hlsUrl", hlsUrl);
    formData.append("category", categoryArray);
    formData.append("episodes", JSON.stringify(episodesArray));
    if (availableMovieDate) {
      formData.append("availableDate", availableMovieDate);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file uploads
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUploadResponse(response.data);

      setTitle("");
      setDesc("");
      setHlsUrl("");
      setSelectedFile(null);
      setCategoryInput("");
      setCategoryArray([]);
      setEpisodesArray([]);
      setAvailableMovieDate("");
      setIsMovieDateEnabled(false);
    } catch (error) {
      console.error("Upload error:", error);
      setUploadResponse({ error: error.message });
    }
  };

  useEffect(() => {
    if (
      uploadResponse &&
      uploadResponse.message === "Photo uploaded successfully!"
    ) {
      const filename = uploadResponse.filename; // Assuming server response provides filename
      setImageUrl(`/uploads/${filename}`); // Update with the actual URL based on your server setup
    }
  }, [uploadResponse]);

  return (
    <div className={styles.main}>
      <Link className={styles.gobacklink} to="/admin">
        {"<--- Wróć"}
      </Link>
      <form onSubmit={handleSubmit} className={styles.form}>
        <Box spacing={4} align="stretch">
          <TextInput
            id="title"
            label="Tytuł"
            value={title}
            onChange={handleTitleChange}
          />
          <TextInput
            id="desc"
            label="Opis"
            value={desc}
            onChange={handleDescChange}
          />
          <TextInput
            id="hlsUrl"
            label="HLS Odcinka nr 1 albo Film"
            value={hlsUrl}
            onChange={handleHlsUrlChange}
          />
          <Checkbox
            isChecked={isMovieDateEnabled}
            onChange={handleMovieTimeCheckboxChange}
            mb={2}
            color="#fff"
          >
            Ustaw datę dostępności filmu lub 1 odcinka (Domyślnie jest od razu)
          </Checkbox>
          {isMovieDateEnabled && (
            <Input
              id="availableMovieDate"
              name="availableMovieDate"
              type="datetime-local"
              value={availableMovieDate}
              onChange={(e) => setAvailableMovieDate(e.target.value)}
              placeholder="Data dostępności od"
              mb={5}
            />
          )}
          <CategoryInput
            handleCategoryDelete={handleCategoryDelete}
            categoryInput={categoryInput}
            handleCategoryChange={handleCategoryChange}
            handleCategoryAdd={handleCategoryAdd}
            categoryArray={categoryArray}
          />

          <EpisodeInput
            episodeInput={episodeInput}
            handleEpisodeChange={handleEpisodeChange}
            handleEpisodeAdd={handleEpisodeAdd}
            episodesArray={episodesArray}
            handleEpisodeDelete={handleEpisodeDelete}
            isEpisodeDateEnabled={isEpisodeDateEnabled}
            handleEpisodeTimeCheckboxChange={handleEpisodeTimeCheckboxChange}
          />

          <FormControl>
            <FormLabel color="#fff" htmlFor="file">
              File
            </FormLabel>
            <Input type="file" id="file" onChange={handleFileChange} />
          </FormControl>
          <Button isFullWidth mt={3} type="submit" variantColor="blue">
            Upload Video
          </Button>
        </Box>
        {uploadResponse && (
          <Box mt={4} className={styles.response}>
            {uploadResponse.error ? (
              <Text color="red.500">Error: {uploadResponse.error}</Text>
            ) : (
              <Text color="green.500">Success! Video uploaded.</Text>
            )}
          </Box>
        )}
      </form>
    </div>
  );
}

export default PhotoUploader;
