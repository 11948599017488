import React from "react";
import { Button } from "@chakra-ui/core";
import { useHistory } from "react-router-dom";
import styles from "./admin.module.css";
import Navbar from "../../Navbar";

function Admin() {
  let history = useHistory();

  const handleAddVideo = () => {
    history.push("/admin/add-video");
  };

  const handleManageVideo = () => {
    history.push("/admin/manage-video");
  };

  const handleEditAd = () => {
    history.push("/admin/edit-ad");
  };

  const handleEditLiveHls = () => {
    history.push("/admin/edit-live-hls");
  };

  return (
    <div className={styles.main}>
      <Navbar />
      <div className={styles.buttons}>
        <Button w={200} mr={5} variantColor="green" onClick={handleAddVideo}>
          Dodaj Video
        </Button>
        <Button w={200} mr={5} variantColor="green" onClick={handleManageVideo}>
          Zarządzaj Video
        </Button>
        <Button w={200} mb={5} variantColor="green" onClick={handleEditAd}>
          Zmień Pre-load Reklamę
        </Button>
        <Button w={200} variantColor="green" onClick={handleEditLiveHls}>
          Zmień Live Hls
        </Button>
      </div>
    </div>
  );
}

export default Admin;
