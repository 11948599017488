import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import logo from "./assets/logo.svg";
import LoginModal from "./components/LoginModal/loginModal";
import RegisterModal from "./components/RegisterModal/registerModal";
import { toggleLoginModal } from "./redux/userSlice";
import styles from "./navbar.module.css";

function Navbar() {
  const { isLoggedIn, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogin = () => {
    dispatch(toggleLoginModal());
  };

  return (
    <nav className={styles.main}>
      <div className={styles.logoWrapper} onClick={() => history.push("/")}>
        <img src={logo} alt="logo" width={100} height={80} />
      </div>
      <div className={styles.auth}>
        {isLoggedIn ? (
          <ul className={styles.menu}>
            <li>
              <Link to="/">TV na żywo</Link>
            </li>
            <li>
              <Link to="/vod">VOD</Link>
            </li>
            <li>
              <Link to={`/user/${user._id}`}>Moje konto</Link>
            </li>
          </ul>
        ) : (
          <>
            <LoginModal />
            <RegisterModal />
            <ul className={styles.menu}>
              <li>
                <Link to="/">Live</Link>
              </li>
              <li>
                <Link to="/vod">Katalog VOD</Link>
              </li>
              <li onClick={handleLogin}>Zaloguj</li>
            </ul>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
