import React, { useState, useEffect } from "react";
import VodPlayer from "../VodPlayer/VodPlayer";
import { Spinner, Button, AspectRatioBox, Image, Box } from "@chakra-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import styles from "./video.module.css";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../Navbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import { toggleLoginModal } from "../../redux/userSlice";

const cookies = new Cookies();
const polishMonths = {
  1: "Stycznia",
  2: "Lutego",
  3: "Marca",
  4: "Kwietnia",
  5: "Maja",
  6: "Czerwca",
  7: "Lipca",
  8: "Sierpnia",
  9: "Września",
  10: "Października",
  11: "Listopada",
  12: "Grudnia",
};

function Video() {
  const [video, setVideo] = useState({ filename: "" });
  const [currHls, setCurrHls] = useState("");
  const [currEpisode, setCurrEpisode] = useState(1);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isVideoLiked, setIsVideoLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [menuItem, setMenuItem] = useState(0);

  const { user } = useSelector((state) => state.user);

  const token = cookies.get("TOKEN");

  const currentDate = new Date().toISOString();

  const dispatch = useDispatch();
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    const getVideo = async () => {
      setIsLoadingVideo(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api${location.pathname}`
        );
        setVideo(response.data);
        setCurrHls(response.data.hlsUrl);
        setLikes(response.data.likes);
        setIsLoadingVideo(false);
      } catch (err) {
        console.log(err);
        setIsLoadingVideo(false);
      }
    };

    const getUserData = async () => {
      setIsLoadingUser(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/getUser`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (res.data && res.data.likedVideos.includes(video._id)) {
          setIsVideoLiked(true);
        }
        setIsLoadingUser(false);
      } catch (err) {
        console.log(err);
        setIsLoadingUser(false);
      }
    };

    getVideo();
    getUserData();
  }, [token, location.pathname, history, video._id]);

  const videoJsOptions = React.useMemo(
    () => ({
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: false,
      aspectRatio: "16:9",
      language: "PL",
      poster: `${process.env.REACT_APP_API_URL + "/uploads/" + video.filename}`,
      sources: [
        {
          src: currHls,
          type: "application/x-mpegURL",
        },
      ],
      notSupportedMessage:
        "Przepraszamy, nie można odtworzyć tego źródła multimedialnego.",
      html5: {
        vhs: {
          overrideNative: true,
        },
        nativeAudioTracks: false,
        nativeVideoTracks: false,
      },
    }),
    [currHls, video.filename, currEpisode]
  );

  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.hlsQualitySelector({
      displayCurrentQuality: true,
    });
  };

  const handleEpisodeChange = (e) => {
    if (e.target.value === 1) {
      setCurrHls(video.hlsUrl);
      setCurrEpisode(1);
      setMenuItem(0);
      document
        .getElementById("target-div")
        .scrollIntoView({ behavior: "smooth" });
      return;
    }
    setCurrHls(
      video.episodes.find(
        (episode) => episode.episodeNumber === Number(e.target.value)
      ).hls
    );
    setCurrEpisode(Number(e.target.value));
    setMenuItem(0);
    document
      .getElementById("target-div")
      .scrollIntoView({ behavior: "smooth" });
  };

  const handleLike = async () => {
    let newVideoArr = [...user.likedVideos];
    let newLikes = likes;

    if (isVideoLiked) {
      newVideoArr = user.likedVideos.filter((videoId) => videoId !== video._id);
      setLikes(likes - 1);
      newLikes = newLikes - 1;
      setIsVideoLiked(false);
    } else {
      newVideoArr.push(video._id);
      setLikes(likes + 1);
      newLikes = newLikes + 1;
      setIsVideoLiked(true);
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/toggle-like-video`,
        { videoIdArr: newVideoArr, videoId: video._id, likes: newLikes },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoadingVideo || isLoadingUser) {
    return (
      <>
        <Navbar />
        <div className={styles.spinner}>
          <Spinner thickness="4px" color="#d71961" size="xl" />
        </div>
      </>
    );
  }

  return (
    <div>
      <Navbar />
      {currHls ? (
        <div className={styles.main} id="target-div">
          <div className={styles.videoBlock}>
            {token &&
            user.subscription === "premium" &&
            (!video.availableDate ||
              new Date(video.availableDate).toISOString() <= currentDate) ? (
              <VodPlayer options={videoJsOptions} onReady={handlePlayerReady} />
            ) : (
              <AspectRatioBox ratio={16 / 9} className={styles.poster}>
                <Image
                  src={`${
                    process.env.REACT_APP_API_URL + "/uploads/" + video.filename
                  }`}
                  alt="film"
                  objectFit="cover"
                />
              </AspectRatioBox>
            )}
          </div>
          <div className={styles.desc}>
            <div>
              <div className={styles.navigation}>
                <span
                  onClick={() => setMenuItem(0)}
                  className={
                    menuItem === 0
                      ? styles.navigationItemActive
                      : styles.navigationItem
                  }
                >
                  Opis
                </span>
                {video.episodes.length > 0 && (
                  <span
                    onClick={() => setMenuItem(1)}
                    className={
                      menuItem === 1
                        ? styles.navigationItemActive
                        : styles.navigationItem
                    }
                  >
                    Oglądaj odcinki
                  </span>
                )}
              </div>
              {video.availableDate &&
                new Date(video.availableDate).toISOString() >= currentDate && (
                  <h2 style={{ color: "#d71961", fontWeight: "700" }}>
                    Premiera już {new Date(video.availableDate).getDate()}{" "}
                    {polishMonths[new Date(video.availableDate).getMonth() + 1]}
                    , nie przegap!
                  </h2>
                )}
              {menuItem === 0 ? (
                <div>
                  <h1 className={styles.title}>{video.title}</h1>
                  {video.episodes.length > 0 && (
                    <h2 className={styles.episodeTitle}>
                      ODCINEK {currEpisode}
                    </h2>
                  )}
                  <p className={styles.info}>{video.info}</p>
                </div>
              ) : (
                <div className={styles.episodeContainer}>
                  <ul className={styles.episodesList}>
                    <li
                      onClick={handleEpisodeChange}
                      value="1"
                      className={styles.episode}
                    >
                      ODCINEK 1
                    </li>
                    {video.episodes
                      .filter(
                        (episode) =>
                          !episode.availableDate ||
                          new Date(episode.availableDate).toISOString() <=
                            currentDate
                      ) // Filter based on availability
                      .sort((a, b) => a.episodeNumber - b.episodeNumber)
                      .map((episode) => (
                        <li
                          className={styles.episode}
                          key={episode.episodeNumber}
                          value={episode.episodeNumber}
                          onClick={handleEpisodeChange}
                        >{`ODCINEK ${episode.episodeNumber}`}</li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
            {token ? (
              user.subscription === "premium" ? (
                <Button
                  leftIcon={isVideoLiked ? "check" : "add"}
                  variant="outline"
                  fontWeight={400}
                  onClick={handleLike}
                  borderColor={isVideoLiked ? "#d71961" : "#8D8D8D"}
                  color={isVideoLiked ? "#d71961" : "#FFF"}
                  borderWidth="1px"
                  width={170}
                  _hover={{}}
                  _focus={{}}
                  mt={5}
                >
                  Ulubione
                </Button>
              ) : (
                <Button
                  borderColor="#686868"
                  mt={5}
                  color="#fff"
                  _hover={{ color: "#303030", backgroundColor: "#fff" }}
                  variant="outline"
                  width="300px"
                  borderRadius="15px"
                  onClick={() => history.push("/oferta")}
                >
                  Wybierz plan i oglądaj
                </Button>
              )
            ) : (
              <Button
                borderColor="#686868"
                mt={5}
                color="#fff"
                _hover={{ color: "#303030", backgroundColor: "#fff" }}
                variant="outline"
                width="300px"
                borderRadius="15px"
                onClick={() => dispatch(toggleLoginModal())}
              >
                Zaloguj się, aby oglądać
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.spinnerDiv}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Video;
