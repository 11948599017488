import React, { useState } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  SlideIn,
  useDisclosure,
  PseudoBox,
} from "@chakra-ui/core";
import { Formik, Form } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function EditPasswordModal() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [error, setError] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const token = cookies.get("TOKEN");

  const handleSubmit = async (event) => {
    if (newPassword !== newPassword2) {
      setError(
        "Ups! Wygląda na to, że podane przez Ciebie hasła nie pasują do siebie. Proszę sprawdź ponownie i spróbuj jeszcze raz."
      );
      return;
    }

    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/change-password`,
        {
          currentPassword: oldPassword,
          newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data) {
        toast({
          title: "Twoje hasło zostało pomyślnie zmienione",
          description: "Hasło zostało pomyślnie zmienione na nowe",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      }
    } catch (err) {
      console.error("Failed:", err.response?.data);
      setError(err.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      <Button variantColor="yellow" onClick={onOpen}>
        Zmień hasło
      </Button>
      <SlideIn in={isOpen}>
        {(slideStyles) => (
          <Modal
            blockScrollOnMount={false}
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={"xl"}
          >
            <ModalOverlay opacity={slideStyles.opacity} />
            <ModalContent {...slideStyles} borderRadius={20}>
              <Formik>
                <Form onSubmit={handleSubmit}>
                  <ModalHeader fontWeight={400}>Zmień hasło</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={3}>
                    <FormControl>
                      <FormLabel fontWeight={400}>
                        Wpisz Aktualne hasło
                      </FormLabel>
                      <Input
                        borderColor={"#8D8D8D"}
                        borderWidth={"2px"}
                        type="password"
                        id="old-password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        autoComplete="current-password"
                      />
                    </FormControl>

                    <FormControl mb={3} mt={4}>
                      <FormLabel fontWeight={400} htmlFor="new-password">
                        Wpisz Nowe hasło
                      </FormLabel>
                      <Input
                        borderColor={"#8D8D8D"}
                        borderWidth={"2px"}
                        type="password"
                        id="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </FormControl>

                    <FormControl mb={3} mt={4}>
                      <FormLabel fontWeight={400} htmlFor="new-password2">
                        Wpisz Ponownie Nowe hasło
                      </FormLabel>
                      <Input
                        borderColor={"#8D8D8D"}
                        borderWidth={"2px"}
                        type="password"
                        id="new-password2"
                        value={newPassword2}
                        onChange={(e) => setNewPassword2(e.target.value)}
                      />
                    </FormControl>
                    {error && (
                      <p style={{ color: "red", fontWeight: "700" }}>{error}</p>
                    )}
                  </ModalBody>

                  <ModalFooter>
                    <PseudoBox
                      as="button"
                      fontWeight={400}
                      onClick={onClose}
                      mr={3}
                      py={2}
                      px={3}
                      borderRadius="7px"
                      variant="outline"
                      _hover={{ bg: "gray.200" }}
                      borderColor="#8D8D8D"
                      borderWidth="2px"
                      type="button"
                    >
                      Zamknij
                    </PseudoBox>
                    <PseudoBox
                      as="button"
                      py={2}
                      px={3}
                      fontWeight={400}
                      color="#fff"
                      borderRadius="7px"
                      _hover={{ bg: "gray.700" }}
                      variantColor="black"
                      backgroundColor="#646464"
                      type="submit"
                    >
                      Zmień hasło
                    </PseudoBox>
                  </ModalFooter>
                </Form>
              </Formik>
            </ModalContent>
          </Modal>
        )}
      </SlideIn>
    </>
  );
}

export default EditPasswordModal;
