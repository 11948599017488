import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Link,
  Icon,
  SlideIn,
} from "@chakra-ui/core";
import { useSelector } from "react-redux";
import { toggleSubscribeModal } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import styles from "./subscribeModal.module.css";

function SubscribeModal() {
  const { isSubscribeModalOpen } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  return (
    <>
      <SlideIn in={isSubscribeModalOpen}>
        {(slideStyles) => (
          <Modal
            blockScrollOnMount={false}
            isOpen={isSubscribeModalOpen}
            isCentered={true}
            onClose={() => dispatch(toggleSubscribeModal())}
          >
            <ModalOverlay opacity={slideStyles.opacity} />
            <ModalContent {...slideStyles}>
              <ModalHeader>Subskrybuj i oglądaj bez ograniczeń</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Subskrybuj, by oglądać telewizję bez reklam i mieć dostęp do
                biblioteki VOD – jedynie <strong>9,90 zł/miesiąc</strong>.
              </ModalBody>

              <ModalFooter>
                <Link
                  href="https://buy.stripe.com/test_cN29CX6lYaKpdva8ww"
                  isExternal
                  className={styles.subscribeLink}
                >
                  Subskrybuj do Premium <Icon name="external-link" mx="2px" />
                </Link>

                <Button
                  variantColor="blue"
                  onClick={() => dispatch(toggleSubscribeModal())}
                >
                  Zamknij
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </SlideIn>
    </>
  );
}

export default SubscribeModal;
