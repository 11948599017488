import React, { useEffect, useState, useRef } from "react";
import styles from "./vod.module.css";
import { setVideo } from "../../redux/videoSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Spinner,
  InputGroup,
  InputRightElement,
  IconButton,
  Input,
  Box,
  Icon,
  Button,
  AspectRatioBox,
  Image,
} from "@chakra-ui/core";
import SubscribeModal from "../SubscribeModal/subscribeModal";
import axios from "axios";
import Carousel from "../Carousel/carousel";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import VodPlayer from "../VodPlayer/VodPlayer";

function Vod() {
  const [videos, setVideos] = useState([]);
  const [categoryMap, setCategoryMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const { user } = useSelector((state) => state.user);

  const inputRef = useRef(null);

  let history = useHistory();

  const dispatch = useDispatch();

  const handleSearchClick = () => {
    setIsExpanded(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 300); // Delay to allow for transition
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        // Fetch Videos
        const videosResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/getVideos`
        );
        setVideos(videosResponse.data);

        // Fetch Categories
        const categoriesResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/categories`
        );
        setCategoryMap(
          categoriesResponse.data.reduce((acc, curr) => {
            acc[Object.keys(curr)[0]] = curr[Object.keys(curr)[0]];
            return acc;
          }, {})
        );

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePosterClick = (videoId) => {
    dispatch(setVideo(videoId));
    history.push(`/vod/${videoId}`);
  };

  if (isLoading) {
    return (
      <>
        <div className={styles.spinner}>
          <Spinner thickness="4px" color="#d71961" size="xl" />
        </div>
      </>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.desc}>
        <h2 className={styles.title}>Oglądaj VOD</h2>
        {user.subscription === "premium" ? (
          <>
            <p>Dziękujemy że jesteś z nami</p>
          </>
        ) : (
          <>
            <p>Treści premium dla subskrybentów</p>
            <Link style={{ textDecoration: "underline" }} to="/oferta">
              Skorzystaj z 7-dniowego okresu próbnego.
            </Link>
          </>
        )}
      </div>
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-end"
        position="relative"
      >
        <IconButton
          aria-label="Search"
          icon="search"
          onClick={handleSearchClick}
          display={!isExpanded ? "block" : "none"}
          color="#fff"
          border="1px solid #8D8D8D"
          padding="0 15px"
          borderRadius="15px"
          variant="unstyled"
          height="47px"
          _hover={{ backgroundColor: "#8D8D8D" }}
          marginTop={{ base: "40px", lg: "0" }}
        />
        <InputGroup
          size="md"
          width={isExpanded ? { base: "100%", lg: "40%" } : "0"}
          transition="width 0.3s"
          overflow="hidden"
          marginTop={{ base: "40px", lg: "0" }}
        >
          <Input
            ref={inputRef}
            pr="4.5rem"
            type="text"
            color="#fff"
            placeholder="Szukaj"
            width="100%"
            opacity={isExpanded ? 1 : 0}
            transition="opacity 0.3s"
            borderRadius="10px"
            variant="unstyled"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            height="47px"
            border="1px solid #8D8D8D"
            pl={4}
          />
          <InputRightElement color="#fff" mt={1}>
            <Icon name="search" />
          </InputRightElement>
        </InputGroup>
      </Box>
      <div className={styles.videos}>
        {inputValue.length > 1 ? (
          <div className={styles.searchedVideos}>
            {videos
              .filter((video) =>
                video.title.toLowerCase().includes(inputValue.toLowerCase())
              )
              .map((video) => (
                <AspectRatioBox
                  key={video._id}
                  ratio={16 / 9}
                  className={styles.poster}
                >
                  <Image
                    src={`${
                      process.env.REACT_APP_API_URL +
                      "/uploads/" +
                      video.filename
                    }`}
                    alt="film"
                    objectFit="cover"
                    onClick={() => {
                      handlePosterClick(video._id);
                    }}
                  />
                </AspectRatioBox>
              ))}
          </div>
        ) : (
          <>
            {Object.entries(categoryMap)
              .sort(([categoryA], [categoryB]) =>
                categoryA === "popularne" ? -1 : 1
              )
              .map(([category, videos]) => (
                <Carousel
                  key={category}
                  category={category}
                  videos={videos}
                  handlePosterClick={handlePosterClick}
                />
              ))}
          </>
        )}
      </div>
      <SubscribeModal />
    </div>
  );
}

export default Vod;
