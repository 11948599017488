import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";

function Footer() {
  return (
    <div className={styles.main}>
      <div className={styles.regulations}>
        <div>
          <Link to="/regulamin-serwisu">Regulamin serwisu</Link>
          <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
        </div>

        <span>
          © 2024 TBN Polska powered by Hostline.pl – Hosting dla
          profesjonalistów
        </span>
      </div>
      <div className={styles.socials}>
        <h4 className={styles.findText}>Znajdź nas:</h4>
        <div className={styles.icons}>
          <div className={styles.icon}>
            <a href="https://www.facebook.com/tbnpltv" target="_blank">
              <i className="fa-brands fa-facebook"></i>
            </a>
          </div>
          <div className={styles.icon}>
            <a href="https://www.instagram.com/tbnpolska" target="_blank">
              <i className="fa-brands fa-instagram"></i>
            </a>
          </div>
          <div className={styles.icon}>
            <a
              href="https://www.youtube.com/channel/UCohXyxqzCibIZl022co9Fzg"
              target="_blank"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
          </div>
          <div className={styles.icon}>
            <a href="https://www.tiktok.com/@tbnpolska" target="_blank">
              <i className="fa-brands fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
