import React from "react";
import App from "./App";
import ReactDOM from "react-dom";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import ErrorBoundary from "./ErrorBoundary";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./redux/userSlice"; // Import the slice
import videoReducer from "./redux/videoSlice"; // Import the slice

const store = configureStore({
  reducer: {
    user: userReducer,
    video: videoReducer,
  },
});

ReactDOM.render(
  <ErrorBoundary>
    <ThemeProvider>
      <CSSReset />
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
