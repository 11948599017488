import React, { useState } from "react";
import {
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  SlideIn,
  PseudoBox,
} from "@chakra-ui/core";
import { Formik, Form } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  loginUser,
  toggleLoginModal,
  toggleIsUserLoading,
  toggleRegisterModal,
} from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import styles from "./loginModal.module.css";

const cookies = new Cookies();

function LoginModal() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { isLoginModalOpen } = useSelector((state) => state.user);

  const toast = useToast();

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    dispatch(toggleIsUserLoading());
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          email,
          password,
        }
      );

      toast({
        title: "Logowanie zakończone sukcesem.",
        description: "Zalogowałeś się na swoje konto.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      cookies.set("TOKEN", response.data.token, {
        path: "/",
      });

      dispatch(loginUser(response.data.user));
      dispatch(toggleIsUserLoading());
    } catch (err) {
      console.error("Login Failed:", err.response?.data);
      setError(err.response?.data?.message || "Wystąpił błąd");
      dispatch(toggleIsUserLoading());
    }
  };

  const handleRegisterClick = () => {
    dispatch(toggleLoginModal());
    dispatch(toggleRegisterModal());
  };

  return (
    <>
      <SlideIn in={isLoginModalOpen}>
        {(slideStyles) => (
          <Modal
            blockScrollOnMount={false}
            isCentered
            isOpen={isLoginModalOpen}
            onClose={() => dispatch(toggleLoginModal())}
            size={"xl"}
          >
            <ModalOverlay opacity={slideStyles.opacity} />
            <ModalContent {...slideStyles} borderRadius={20}>
              <Formik>
                <Form onSubmit={handleSubmit}>
                  <ModalHeader p={0}>
                    <div className={styles.modalImg}>
                      <p>oglądaj telewizję live za darmo</p>
                      <div className={styles.playBtn}>
                        <div className={styles.triangle}></div>
                      </div>
                    </div>
                  </ModalHeader>
                  <div className={styles.modalBody}>
                    <ModalHeader fontWeight={400}>Zaloguj się</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={3}>
                      <FormControl>
                        <FormLabel fontWeight={400}>e-mail</FormLabel>
                        <Input
                          borderColor={"#8D8D8D"}
                          borderWidth={"2px"}
                          type="email"
                          id="email"
                          aria-describedby="email-helper-text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          autoComplete="email"
                        />
                      </FormControl>

                      <FormControl mb={3} mt={4}>
                        <FormLabel fontWeight={400} htmlFor="password">
                          hasło
                        </FormLabel>
                        <Input
                          borderColor={"#8D8D8D"}
                          borderWidth={"2px"}
                          type="password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoComplete="current-password"
                        />
                      </FormControl>
                      {error && <p className={styles.error}>{error}</p>}
                    </ModalBody>

                    <ModalFooter>
                      <PseudoBox
                        as="button"
                        fontWeight={400}
                        onClick={handleRegisterClick}
                        mr={3}
                        py={2}
                        px={3}
                        borderRadius="7px"
                        variant="outline"
                        _hover={{ bg: "gray.200" }}
                        borderColor="#8D8D8D"
                        borderWidth="2px"
                        type="button"
                      >
                        Utwórz konto
                      </PseudoBox>
                      <PseudoBox
                        as="button"
                        py={2}
                        px={3}
                        color="#fff"
                        _hover={{ bg: "gray.700" }}
                        borderRadius="7px"
                        fontWeight={400}
                        backgroundColor="#646464"
                        type="submit"
                      >
                        Zaloguj się
                      </PseudoBox>
                    </ModalFooter>
                  </div>
                </Form>
              </Formik>
            </ModalContent>
          </Modal>
        )}
      </SlideIn>
    </>
  );
}

export default LoginModal;
