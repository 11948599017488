import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUserLoading: false,
  isLoggedIn: false,
  isLoginModalOpen: false,
  isSubscribeModalOpen: false,
  isRegisterModalOpen: false,
  user: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser(state, action) {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logoutUser(state) {
      state.isLoggedIn = false;
      state.isLoginModalOpen = false;
      state.user = {};
    },
    toggleIsUserLoading(state) {
      state.isUserLoading = !state.isUserLoading;
    },
    toggleLoginModal(state) {
      state.isLoginModalOpen = !state.isLoginModalOpen;
    },
    toggleSubscribeModal(state) {
      state.isSubscribeModalOpen = !state.isSubscribeModalOpen;
    },
    toggleRegisterModal(state) {
      state.isRegisterModalOpen = !state.isRegisterModalOpen;
    },
  },
});

export const {
  loginUser,
  logoutUser,
  toggleLoginModal,
  toggleRegisterModal,
  toggleIsUserLoading,
  toggleSubscribeModal,
} = userSlice.actions;
export default userSlice.reducer;
