import React, { useState, useEffect } from "react";
import {
  Input,
  FormControl,
  FormLabel,
  Box,
  useToast,
  Button,
} from "@chakra-ui/core";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import styles from "./editAds.module.css";
import axios from "axios"; // For making API requests

const cookies = new Cookies();

function EditAds(props) {
  const [url, setUrl] = useState("");

  const token = cookies.get("TOKEN");

  const toast = useToast();

  const handleUrlChange = (event) => setUrl(event.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/edit-ad`,
        { url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast({
        title: response.data.message,
        description: "You've succesfully edit your ad on live player.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  useEffect(() => {
    const getAd = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get-ad`
        );

        setUrl(response.data.hls);
      } catch (err) {
        console.log(err);
      }
    };

    getAd();
  }, []);

  return (
    <div className={styles.main}>
      <Link className={styles.gobacklink} to="/admin">
        {"<--- Wróć"}
      </Link>
      <form onSubmit={handleSubmit} className={styles.form}>
        <Box spacing={4} align="stretch">
          <FormControl>
            <FormLabel color="#fff" htmlFor="hls">
              HLS REKLAMY:
            </FormLabel>
            <Input id="hls" value={url} onChange={handleUrlChange} />
          </FormControl>
        </Box>
        <Button isFullWidth mt={3} type="submit" variantColor="blue">
          Save Edit
        </Button>
      </form>
    </div>
  );
}

export default EditAds;
