import React from "react";
import Navbar from "../../Navbar";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Button, Spinner } from "@chakra-ui/core";
import { toggleLoginModal } from "../../redux/userSlice";
import Footer from "../Footer/Footer";
import styles from "./offer.module.css";

function Offer(props) {
  const { user, isUserLoading } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleClick = () => {
    console.log("FDFD")
  };

  const handleStop = (e) => {
    e.preventDefault();
    dispatch(toggleLoginModal());
  };

  return (
    <>
      <Navbar />
      {isUserLoading ? (
        <div className={styles.spinner}>
          <Spinner thickness="4px" color="#d71961" size="xl" />
        </div>
      ) : (
        <div className={styles.main}>
          <h3 className={styles.title}>Wybierz plan odpowiedni dla siebie</h3>
          <div className={styles.offers}>
            <Link to="/" className={styles.linkToMain}>
              <div className={styles.freeBox}>
                <p className={styles.freeBoxInfo}>
                  Oglądaj telewizję LIVE za darmo
                </p>
                <div className={styles.playBtn}>
                  <div className={styles.triangle}></div>
                </div>
              </div>
            </Link>

            <div className={styles.payedBox}>
              <a
                href={`https://buy.stripe.com/bIY5mpa945jsaaI7st?prefilled_email=${encodeURIComponent(
                  user.email
                )}&locale=pl&client_reference_id=${user._id}`}
                target="_blank"
                onClick={user.email ? handleClick : handleStop}
              >
                <p className={styles.payedBoxInfo}>subskrypcja</p>
                <p className={styles.priceOne}>9.90 zł</p>
                <ul className={styles.payedBoxPerks}>
                  <li>
                    <Icon mr={2} name="check" />
                    7-dniowy bezpłatny okres próbny
                  </li>
                  <li>
                    <Icon mr={2} name="check" />
                    telewizja na żywo bez reklam
                  </li>
                  <li>
                    <Icon mr={2} name="check" />
                    treści premium w VOD
                  </li>
                </ul>
                <div className={styles.payedBoxDesc}>
                  <p>
                    Otrzymaj dostęp <strong>bez ograniczeń</strong> do LIVE TV
                    oraz VOD.
                  </p>
                  <p style={{ marginTop: "5px" }}>
                    Anulowanie subskrypcji możliwe jest w{" "}
                    <strong>dowolnym momencie.</strong>
                  </p>
                </div>
                <div className={styles.mobileBtn}>
                  <Button
                    fontWeight={400}
                    variantColor="black"
                    backgroundColor="#303030"
                    fontFamily={"Montserrat"}
                    type="submit"
                    isFullWidth
                    borderRadius="15px"
                    mt={5}
                    mb={5}
                  >
                    Subskrybuję
                  </Button>
                </div>
              </a>
            </div>

            <div className={styles.payedBox}>
              <a
                href={`https://buy.stripe.com/fZebKN6WS7rA82A4gg?prefilled_email=${encodeURIComponent(
                  user.email
                )}&locale=pl&client_reference_id=${user._id}`}
                target="_blank"
                onClick={user.email ? handleClick : handleStop}
              >
                <p className={styles.payedBoxInfo}>subskrypcja</p>
                <p className={styles.priceTwo}>100 zł</p>
                <ul className={styles.payedBoxPerks}>
                  <li>
                    <Icon mr={2} name="check" />
                    7-dniowy bezpłatny okres próbny
                  </li>
                  <li>
                    <Icon mr={2} name="check" />
                    telewizja na żywo bez reklam
                  </li>
                  <li>
                    <Icon mr={2} name="check" />
                    treści premium w VOD
                  </li>
                </ul>
                <div className={styles.payedBoxDesc}>
                  <p>
                    Otrzymaj dostęp <strong>bez ograniczeń</strong> do LIVE TV
                    oraz VOD.
                  </p>
                  <p style={{ marginTop: "5px" }}>
                    Anulowanie subskrypcji możliwe jest w{" "}
                    <strong>dowolnym momencie.</strong>
                  </p>
                </div>
                <div className={styles.mobileBtn}>
                  <Button
                    fontWeight={400}
                    variantColor="black"
                    backgroundColor="#303030"
                    fontFamily={"Montserrat"}
                    type="submit"
                    isFullWidth
                    mt={5}
                    borderRadius="15px"
                    mb={5}
                  >
                    Subskrybuję
                  </Button>
                </div>
              </a>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}

export default Offer;
