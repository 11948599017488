import React from "react";

function PolitykaPrywatnosci(props) {
  return (
    <div
      style={{
        backgroundColor: "#141414",
        color: "#fff",
        minHeight: "100vh",
        padding: "100px 7%",
      }}
    >
      Niniejszy dokument reguluje Politykę prywatności i plików „cookies”
      serwisu internetowego prowadzonego w domenie TBNGO.PL (zwanego dalej
      Serwisem). Fundacja Trinity Broadcasting Network Polska (zwana dalej
      Usługodawcą) dochowuje wszelkiej należytej staranności w celu poszanowania
      prywatności Użytkowników Serwisu. Administrator danych osobowych
      Administratorem danych osobowych zawartych w serwisie internetowym
      TBNGO.PL jest Trinity Broadcasting Network Polska, ul. Bohaterów Getta
      Warszawskiego 7-9, 81-609 Gdynia, wpisana do Rejestru Stowarzyszeń
      Krajowego Rejestru Sądowego pod numerem KRS 0000531843, NIP 5842738571
      oraz REGON 360162152. Dane osobowe Użytkowników korzystających z usług
      świadczonych drogą elektronicznąza pośrednictwem Serwisu prosimy o
      dobrowolne podanie danych osobowych. Administrator przetwarza pozyskane
      dane na podstawie art. 23 ust. 1 ustawy o ochronie danych osobowych. Dane
      zostają zebrane w szczególności w celu realizacji umowy oraz świadczenia
      usług na rzecz osób, których dotyczą.Zgoda na przetwarzanie danych
      osobowych w celach marketingowych może być cofnięta w każdej chwili.
      Administrator danych osobowych dochowuje szczególnej i należytej
      staranności w celu ochrony praw Użytkowników, których dane podlegają
      przetwarzaniu, a w szczególności zapewnia, aby dane te były: przetwarzane
      zgodnie z prawem, zbierane dla oznaczonych w niniejszym dokumencie i
      zgodnych z prawem celów, nie poddawane dalszemu przetwarzaniu niezgodnemu
      z powyższymi celami, • merytorycznie poprawne i adekwatne w stosunku do
      celów, w jakich są przetwarzane, odpowiednio zabezpieczone, przechowywane
      w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej
      niż jest to niezbędne do osiągnięcia celu przetwarzania. Administrator
      danych osobowych zastrzega, iż zgodnie z przepisami ustawy o ochronie
      danych osobowych, może powierzyć przetwarzanie danych osobowych
      Użytkowników, których danymi administruje, innym firmom w celu
      prawidłowego wykonania przez te firmy czynności związanych z hostingiem,
      administracją, utrzymywaniem oraz zarządzaniem Serwisem, a także z
      dochodzeniem roszczeń oraz wyjaśnieniem okoliczności niedozwolonego
      korzystania z usług świadczonych drogą elektroniczną. Uprawnienia
      Użytkownika Użytkownikom przysługuje prawo kontroli przetwarzania danych,
      które ich dotyczą, zawartych w zbiorach danych Usługodawcy. Jest to
      zwłaszcza prawo do dostępudo treści swoich danych oraz ich poprawiania, a
      także żądania uzupełnienia, uaktualnienia, sprostowania danych osobowych,
      czasowego lub stałego wstrzymania ich przetwarzania lub ich usunięcia,
      jeżeli są one niekompletne, nieaktualne, nieprawdziwe lub zostały zebrane
      z naruszeniem ustawy albo są już zbędne do realizacji celu, dla którego
      zostały zebrane. Dane osobowe podane przez Użytkownika w czasie
      rejestracji mogą być przez niego samodzielnie edytowane i usuwane w
      zakładce „Konto Użytkownika”. Użytkownikowi przysługuje prawo zakończenia
      w każdej chwili korzystania z usługi świadczonej drogą elektroniczną, w
      tym prowadzenia Konta Użytkownika oraz usługi Newsletter. Użytkownicy mogą
      również skontaktować się ze Usługodawcą w celu wykonania przysługującego
      im prawa dostępu, modyfikacji i usunięcia danych bądź w celu sprzeciwienia
      się ich przetwarzaniu, za pośrednictwem Danych Kontaktowych Usługodawcy
      udostępnionych na stronie internetowej, w szczególności poprzez wysłanie
      wiadomości na adres poczty elektronicznej: biuro@tbnpolska.tv. Dzienniki
      systemowe To wewnętrzne dzienniki zdarzeń serwera Serwisu, automatycznie
      zapisujące żądania stron wysyłane, gdy Użytkownicy odwiedzają jego
      witryny. Dzienniki systemowe zawierają żądanie strony wysłane przez
      Użytkownika, adres IP, typ przeglądarki, język przeglądarki, datę i
      godzinę żądania oraz co najmniej jeden plik „cookie”, który może
      jednoznacznie identyfikować przeglądarkę Użytkownika. Pliki „cookies” To
      niewielkie pliki zawierające ciąg znaków, które są wysyłane i zapisywanena
      urządzeniu końcowym (np. komputerze, laptopie, tablecie, smartfonie)
      używanym przez Użytkownika podczas odwiedzania przez niego Serwisu.
      Informacje te przesyłane są do schowka używanej przeglądarki, która wysyła
      je z powrotem przy następnych wejściach na stronę internetową. Pliki
      „cookies” zawierają informacje niezbędne do prawidłowego korzystania z
      Serwisu internetowego TBNGO.PL. Najczęściej zawierają one nazwę strony
      internetowej, z której pochodzą, czas przechowywania ich na urządzeniu
      końcowym oraz unikalny numer. Pliki „cookies” wysyłane za pośrednictwem
      Serwisu mogą pochodzić z serwisów zewnętrznych: YouTube
      (https://www.youtube.com) lub Vimeo (https://www.vimeo.com), w przypadku
      korzystania przez Użytkownika z funkcjonalności, pozwalających na
      wyświetlanie zasobów danego serwisu. Pliki te pozwalają na rozpoznanie
      urządzenia Użytkownika i wyświetlenie strony internetowej Serwisu
      dostosowanej do jego indywidualnych oczekiwań, przez co korzystanie ze
      stron staje się łatwiejsze i przyjemniejsze. Dzięki zapisaniu tych plików
      na urządzeniu Użytkownika możliwe jest m. in. zapamiętanie danych
      logowania, utrzymywanie sesji po zalogowaniu, czy dostosowanie strony do
      preferencji Użytkownika, takich jak np. rozmieszczenie treści, język czy
      jej kolor. Jakie pliki „cookies” są wykorzystywane? Na stronie
      internetowej TBNGO.PL używane są głównie trzy kategorie plików „cookies”:
      Pliki niezbędne – pliki te umożliwiają prawidłowe działanie Serwisuoraz
      funkcjonalności, z których Użytkownik chce skorzystać, np.
      uwierzytelniające pliki cookies. Bez ich zapisania na urządzeniu
      Użytkownika korzystanie z Serwisu jest niemożliwe. Pliki funkcjonalne –
      pliki umożliwiające zapamiętanie wybranych przez Użytkownika ustawień i
      dostosowanie ich do jego potrzeb, np. w zakresie wybranego języka,
      rozmiaru czcionki, wyglądu strony internetowej. Pozwalają one Usługodawcy
      na poprawę funkcjonalności i wydajności strony. Pliki biznesowe – do tej
      kategorii należą np. cookies reklamowe. Umożliwiają one realizację modelu
      biznesowego. Ich zablokowanie może obniżyć poziom świadczenia usług ze
      względu na brak możliwości realizacji przez Usługodawcę przychodów
      subsydiujących działanie Serwisu, jednak nie spowoduje niedostępności
      całości funkcjonalności strony. Zarządzanie plikami „cookies” Najczęściej
      ustawienia przeglądarki domyślnie dopuszczają umieszczanie plików
      „cookies” na urządzeniu końcowym. Jeżeli Użytkownik nie zgadza się na
      zapisywanie tych plików, konieczna jest odpowiednia zmiana ustawień
      przeglądarki internetowej. Możliwe jest wyłączenie zapisywania plików
      „cookies” dla wszystkich połączeńz danej przeglądarki lub dla konkretnej
      witryny, a także ich usuwanie. Sposób zarządzania plikami „cookies” zależy
      od używanego oprogramowania, dlatego szczegółowe informacje o możliwości i
      sposobach ich obsługi Użytkownik znajdzie w ustawieniach swojej
      przeglądarki internetowej, z której korzysta w celu połączenia ze stroną
      Serwisu. Zgoda na przetwarzanie plików „cookies” jest dobrowolna. Należy
      jednak pamiętać, że ograniczenia w ich stosowaniu mogą utrudnić lub
      uniemożliwić korzystanie z usług oferowanych na stronie internetowej
      Serwisu. Gromadzenie danych Dane gromadzone w dziennikach systemowych są
      wykorzystywane przez Usługodawcę przez czas nieokreślony wyłącznie w celu
      administrowania Serwisem. Nie są one przekazywane na rzecz podmiotów
      trzecich, z wyłączeniem okoliczności opisanych w niniejszym dokumencie. W
      związku z korzystaniem z Serwisu przez Użytkowników, w sposób automatyczny
      możemy zbierać i zapisywać w dziennikach serwerów szczegóły techniczne
      dotyczące sposobu korzystania z usług, przesłane przez Użytkownika żądania
      związane ze świadczeniem usług drogą elektroniczną, adres IP oraz dane
      techniczne o działaniu Serwisu w związku z czynnościami dokonywanymi przez
      Użytkownika, w szczególności informacje o rozpoczęciu, zakończeniu oraz
      zakresie każdorazowego korzystania z usługi świadczonej drogą
      elektroniczną. Możemy również zbierać informacje, celem przechowywania ich
      lokalnie na urządzeniu Użytkownika, korzystając z mechanizmu pamięci
      przeglądarki. W trakcie świadczenia usług na rzecz Użytkowników, stosujemy
      profesjonalne technologie do gromadzenia i zapisywania informacji, takie
      jak pliki „cookies”. Dostęp do danych „cookies” może posiadać również
      firma Google za pośrednictwem narzędzi Google Analytics oraz Google
      Adwords, w celu sporządzenia statystyk ruchu oraz skuteczności kampanii
      promocyjnych realizowanych przez Usługodawcę za pomocą wyżej wymienionych
      narzędzi. Naszym zaufanym partnerem są również podmioty z grupy
      kapitałowej Wirtualnej Polski. Szczegółowe informacje na temat
      przetwarzania danych Użytkowników przez Wirtualną Polskę znajdują się w
      polityce prywatności Wirtualnej Polski. Dane zgromadzone w Serwisie
      przechowywane są na zewnętrznych bezpiecznychi profesjonalnych serwerach,
      na podstawie umów zawartych przez Usługodawcę. Usługodawca dochowuje
      należytych środków ostrożności, celem zabezpieczenia danych osobowych
      Użytkowników przed dostępem osób nieuprawnionych, zniszczeniem,
      ujawnieniem oraz bezprawnym wykorzystaniem. Do przetwarzania danych
      Użytkowników Serwisu uprawnieni są wyłącznie sami Użytkownicy oraz
      Usługodawca. Inne Serwis może zawierać odnośniki do innych stron
      internetowych. Nie ponosimy odpowiedzialności za obowiązujące na tych
      stronach zasady przestrzegania polityki prywatności. Zalecamy zapoznanie
      się z polityką prywatności tych stron po wejściu na nie. Usługodawca
      zastrzega prawo do zmiany Polityki prywatności i plików „cookies”.
      Niniejszy dokument stanowi załącznik do Regulaminu świadczenia usług drogą
      elektroniczną przez TBNGO.PL.
    </div>
  );
}

export default PolitykaPrywatnosci;
