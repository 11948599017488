import React from "react";
import PhotoUploader from "../PhotoUploader/photoUploader";
import styles from "./insertVideo.module.css";

function InsertVideo(props) {
  return (
    <div>
      <PhotoUploader />
    </div>
  );
}

export default InsertVideo;
