import React from "react";

function RegulaminSerwisu(props) {
  return (
    <div
      style={{
        backgroundColor: "#141414",
        color: "#fff",
        minHeight: "100vh",
        padding: "100px 7%",
      }}
    >
      Regulamin to niniejszy Regulamin świadczenia Usług drogą elektroniczną w
      obszarze domeny internetowej TBNGO.PL, który określa warunki świadczenia
      Usług przez Usługodawcę za pośrednictwem witryny www.tbngo.pl na rzecz
      Użytkowników. Użytkownik to podmiot, który zawiera z Usługodawcą Umowę o
      świadczenie Usługi, pod warunkiem posiadania pełnej zdolności do czynności
      prawnych, lub ograniczonej zdolności do czynności prawnych w przypadkach
      uregulowanych przepisami prawa powszechnie obowiązującego albo bycia osobą
      prawną lub jednostką organizacyjną. Konsument to Użytkownik, który jest
      osobą fizyczną i zawiera Umowę niezwiązaną bezpośrednio z prowadzoną przez
      niego działalnością gospodarczą lub zawodową. Usługodawca to fundacja
      Trinity Broadcasting Network Polska, ul. Bohaterów Getta Warszawskiego
      7-9, 81-609 Gdynia, wpisana do Rejestru Stowarzyszeń Krajowego Rejestru
      Sądowego pod numerem KRS 0000531843, NIP 5842738571 oraz REGON 360162152,
      będąca usługodawcą, administratorem i właścicielem Serwisu. Serwis to
      serwis internetowy prowadzony przez Usługodawcę w języku polskim za
      pośrednictwem witryny www dostępnej w sieci Internet pod adresem URL:
      www.tbngo.pl. Serwis świadczy na rzecz Użytkowników Usługi. Usługa to
      usługa świadczona przez Usługodawcę na rzecz Użytkownika, na podstawie
      Umowy zawartej pomiędzy stronami za pośrednictwem Serwisu, w ramach
      zorganizowanego systemu zawierania umów na odległość, bez jednoczesnej
      fizycznej obecności stron. Umowa to umowa o świadczenie przez Usługodawcę
      na rzecz Użytkownika Usługi. Dane Kontaktowe Usługodawcy to dane
      Usługodawcy, przy użyciu których Użytkownik może się z nim skontaktować,
      tj. Trinity Broadcasting Network Polska, ul. Bohaterów Getta Warszawskiego
      7-9, 81-609 Gdynia, e-mail: biuro@tbnpolska.tv, tel. (+48) 514 514 546.
      Dane Kontaktowe Użytkownika to dane Użytkownika, przy użyciu których
      Usługodawca może skontaktować się z Użytkownikiem, obejmujące adres, adres
      poczty elektronicznej i numer telefonu. Konto Użytkownika to panel
      zarządzający Usługami Użytkownika, dostępny w obszarze Serwisu, pod
      warunkiem Rejestracji i logowania. Rejestracja to utworzenie Konta
      Użytkownika przez Użytkownika, przy użyciu formularza rejestracyjnego
      Serwisu znajdującego się na jego stronie internetowej. Zewnętrzny System
      Płatności to system płatności internetowych, którym posługuje się
      Usługodawca. §1 Postanowienia ogólne Usługodawca na podstawie art. 8 ust.
      1 pkt 1 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
      elektroniczną oraz ustawy z dnia 30 maja 2014 r. o prawach konsumenta
      ustanawia Regulamin, który udostępnia pod adresem URL Serwisu, w
      brzmieniu: www.tbngo.pl/regulations/regulamin-strony. Usługodawca świadczy
      Usługi zgodnie z Regulaminem i przepisami powszechnie obowiązującego
      prawa. Usługodawca udostępnia niniejszy Regulamin na stronie internetowej
      Serwisu. Użytkownicy mogą w dowolnym czasie: uzyskać dostęp do Regulaminu,
      utrwalić go, pozyskać i odtworzyć poprzez wydrukowanie lub zapisanie na
      nośniku danych. Podane na stronie internetowej Serwisu informacje nie
      stanowią oferty Usługodawcy w rozumieniu art. 66 ustawy Kodeks cywilny, a
      jedynie zaproszenie Użytkowników do składania ofert zawarcia umowy,
      zgodnie z art. 71 ustawy Kodeks cywilny. Świadczenie wszystkich Usług
      odbywa się za pośrednictwem strony internetowej Serwisu przez 24 godziny
      na dobę i 7 dni w tygodniu. W celu korzystania z wszystkich
      funkcjonalności Serwisu, konieczne jest dysponowanie przez Użytkownika:
      urządzeniem teleinformatycznym, poprawnie skonfigurowaną przeglądarką
      internetową: Firefox (Wersja 56 oraz wyższe), Chrome (Wersja 59 oraz
      wyższe), Internet Explorer 11 (wyłącznie w trybie Desktop), Microsoft Edge
      (Wersja 25 z EdgeHTML 13), Safari (Wersja 11 oraz wyższe), aktywnym i
      poprawnie skonfigurowanym kontem poczty elektronicznej, aktywnym i
      poprawnie skonfigurowanym dostępem do internetu o minimalnej prędkości 2
      megabitów na sekundę (Mb/s). Dla uzyskania najlepszej jakości zaleca się
      nieograniczone połączenie 4 Mb/s lub szybsze, dostępem do Konta
      Użytkownika. Korzystanie z Serwisu może wiązać się z poniesieniem przez
      Użytkownika kosztów opłat z tytułu dostępu do sieci Internet oraz
      transmisji danych, w zakresie ustanowionym umową z operatorem
      telekomunikacyjnym, z którego usług korzysta Użytkownik. Korzystanie z
      Serwisu następuje poprzez zapoznanie się z jego zawartością. §2
      Rejestracja Konta Użytkownika Umowa o świadczenie Usługi prowadzenia Konta
      Użytkownika zostaje zawarta na czas nieoznaczony, w chwili potwierdzenia
      przez Usługodawcę rejestracji Konta Użytkownika. Przedmiotem niniejszej
      Usługi prowadzenia Konta Użytkownika jest udostępnienie panelu Konta
      Użytkownika, umożliwiającego m. in. zarządzanie danymi Użytkownika i
      zamówieniami. Aby utworzyć Konto Użytkownika, należy dokonać dobrowolnej i
      bezpłatnej rejestracji. Następuje ona poprzez: wypełnienie i wysłanie do
      Usługodawcy formularza rejestracyjnego, który jest udostępniony w obszarze
      strony internetowej Serwisu, albo użycie konta w serwisie społecznościowym
      Facebook lub Google+. Warunkiem prawidłowego wypełnienia formularza
      rejestracyjnego jest uzupełnienie jego wszystkich obowiązkowych oraz
      ewentualnie opcjonalnych pól, przy użyciu prawdziwych, kompletnych i
      dotyczących Użytkownika danych lub informacji. Przed wysłaniem formularza
      rejestracyjnego lub użycie konta, o którym stanowi pkt 3, Użytkownik
      powinien oświadczyć, iż zapoznał się z Regulaminem i akceptuje jego
      postanowienia. Przed wysłaniem formularza rejestracyjnego lub użycie konta
      o którym stanowi pkt 3, poprzez zaznaczenie jego właściwego pola,
      Użytkownik może dobrowolnie oświadczyć, iż wyraża zgodę na przetwarzanie
      swoich danych osobowych, w celach marketingowych przez Usługodawcę.
      Usługodawca informuje, iż cele marketingowe wskazane powyżej, mogą
      obejmować w szczególności przesłanie przez Usługodawcę informacji
      handlowych, przy użyciu danych kontaktowych Użytkownika. Zgoda, o której
      stanowi punkt powyższy może być cofnięta w każdej chwili. Wysłanie
      formularza rejestracyjnego do Usługodawcy lub użycie konta, o którym
      stanowi pkt 3 następuje przy użyciu funkcjonalności Serwisu i za jego
      pośrednictwem. Korzystanie z Konta Użytkownika jest możliwe po jego
      utworzeniu, a następnie zalogowaniu przy użyciu właściwego loginu i hasła.
      Wypowiedzenie umowy o świadczenie Usługi prowadzenia Konta Użytkownika,
      może zostać dokonane bez podania przyczyny i w dowolnym czasie, przy
      użyciu jego funkcjonalności lub poprzez wysłanie oświadczenia Użytkownika
      w tym przedmiocie do Usługodawcy, np. poprzez wiadomość e-mail lub list.
      §3 Standardowe Usługi Serwisu Usługodawca świadczy na rzecz Użytkowników
      następujące bezpłatne Usługi: live streaming stacji telewizyjnej TBN
      Polska, udostępnienie wyszukiwarki, udostępnienie formularza kontaktowego,
      prowadzenie Konta Użytkownika, Newsletter, polecenie Serwisu, VOD. Umowa o
      świadczenie Usługi live streaming stacji telewizyjnej TBN Polska zawierana
      jest na czas oznaczony w chwili rozpoczęcia odtwarzania Usługi i zostaje
      rozwiązana w chwili zaniechania tej czynności przez Użytkownika.
      Przedmiotem niniejszej Umowy jest udostępnienie odtwarzacza do live
      streaming stacji telewizyjnej TBN Polska. Umowa o świadczenie Usługi
      udostępnienia wyszukiwarki zawierana jest na czas oznaczony w chwili
      rozpoczęcia korzystania z wyszukiwarki i zostaje rozwiązana w chwili
      skorzystania z niej lub zaniechania tej czynności przez Użytkownika.
      Przedmiotem niniejszej Umowy jest udostępnienie pola wyszukiwarki,
      umożliwiającej przeprowadzenie wyszukiwania przy wykorzystaniu słów
      kluczowych. Umowa o świadczenie Usługi udostępnienia formularza
      kontaktowego zawierana jest na czas oznaczony w chwili rozpoczęcia
      korzystania z wybranego formularza kontaktowego i zostaje rozwiązana w
      chwili skorzystania z niego lub zaniechania tej czynności przez
      Użytkownika. Przedmiotem niniejszej Umowy jest udostępnienie formularza
      kontaktowego, znajdującego się na stronie internetowej Serwisu, w celu
      wysłania wiadomości do Usługodawcy. Umowa o świadczenie Usługi prowadzenia
      Konta Użytkownika zawierana jest na czas nieoznaczony w chwili Rejestracji
      Konta Użytkownika. Przedmiotem niniejszej Umowy jest udostępnienie panelu
      zarządzającego Usługami. Umowa o świadczenie Usługi Newsletter zawierana
      jest na czas nieoznaczony w chwili otrzymania przez Użytkownika na adres
      poczty elektronicznej potwierdzenia zawarcia Umowy o świadczenie Usługi
      Newsletter przez Usługodawcę. Zamówienie Usługi następuje poprzez użycie
      pola aktywacji Newslettera w formularzu rejestracyjnym lub w innym
      formularzu udostępnionym przez Usługodawcę na stronie internetowej
      Serwisu. Przedmiotem niniejszej Umowy jest świadczenie Usługodawcy na
      rzecz Użytkownika, polegające na przesyłaniu na adres poczty
      elektronicznej wiadomości zawierających informacje dotyczące Usług
      świadczonych przez Usługodawcę. Niniejsza Umowa zostaje rozwiązana z
      chwilą wypisania się przez Użytkownika z subskrypcji przy użyciu linka
      deaktywującego w obszarze wiadomości Newsletter. Umowa o świadczenie
      bezpłatnej Usługi może być rozwiązana przez Użytkownika lub Usługodawcę
      bez podania przyczyny i w każdym czasie, przy zastosowaniu ujętych w
      Regulaminie funkcjonalności lub poprzez wiadomość e-mail, przesłaną pod
      adres e-mail ujęty w Danych Kontaktowych Usługodawcy lub Danych
      Kontaktowych Użytkownika. §4 Wpłata na zbiórkę publiczną Usługodawca
      prowadzi za pośrednictwem Serwisu zbiórkę publiczną. Aby dokonać wpłaty
      ofiary na rzecz zbiórki publicznej realizowanej przez Usługodawcę, należy
      skorzystać z formularza wpłaty. W obszarze formularza, Użytkownik
      przekazujący darowiznę podaje: informacje o darczyńcy, w tym Dane
      Kontaktowe Użytkownika, walutę, w której zostanie przekazana darowizna
      pieniężna, kwotę przekazywanej darowizny. Koszty transakcji ponosi
      Użytkownik. Przez umowę darowizny, Użytkownik zobowiązuje się do
      bezpłatnego świadczenia na rzecz Usługodawcy kosztem swego majątku. Umowa
      darowizny staje się ważna, jeżeli świadczenie o którym stanowi pkt 6
      zostanie spełnione. Usługodawca zobowiązuje się do wykorzystania
      otrzymanych środków pieniężnych wyłącznie w obszarze realizacji celów
      statutowych fundacji Trinity Broadcasting Network Polska. Potwierdzenie
      wykonania przelewu stanowi dowód przekazania darowizny na rzecz
      Usługodawcy. §5 Live streaming stacji telewizyjnej TBN Polska Usługodawca
      świadczy na rzecz Użytkowników nieodpłatną Usługę live streaming stacji
      telewizyjnej TBN Polska, której przedmiot stanowi umożliwienie odtwarzania
      cyfrowych plików wideo, odtwarzanych strumieniowo na stronie internetowej
      Serwisu. Przez zamówienie Usługi Użytkownik nabywa licencję do wybranego
      materiału video, obejmującą prawo jego ograniczonego w czasie utrwalania i
      wprowadzania do pamięci komputera, w celu odtworzenia dla potrzeb użytku
      własnego, o charakterze niekomercyjnym. W celu skorzystania z Usługi, nie
      wymaga się posiadania zarejestrowanego Konta Użytkownika i zalogowania.
      Wymagane jest natomiast dysponowanie urządzeniami technicznymi lub
      oprogramowaniem, o których stanowią informacje na stronie internetowej
      Serwisu. Zamówienie Usługi można składać za pośrednictwem strony
      internetowej Serwisu przez 7 dni w tygodniu i 24 godziny na dobę, przy
      użyciu funkcjonalności Serwisu. Złożenie zamówienia może nastąpić poprzez
      użycie właściwego przycisku, jest równoznaczne ze złożeniem Usługodawcy
      przez Użytkownika oferty zawarcia umowy o świadczenie Usługi VOD. §6 VOD
      Usługodawca świadczy na rzecz Użytkowników nieodpłatną Usługę VOD, której
      przedmiot stanowi umożliwienie odtwarzania cyfrowych plików wideo,
      uprzednio opublikowanych na stronach internetowych Serwisu. Przez
      zamówienie Usługi VOD, Użytkownik nabywa licencję do wybranego materiału
      video, obejmującą prawo jego ograniczonego w czasie utrwalania i
      wprowadzania do pamięci komputera, w celu odtworzenia dla potrzeb użytku
      własnego, o charakterze niekomercyjnym. W celu skorzystania z Usługi,
      wymaga się posiadania zarejestrowanego Konta Użytkownika i zalogowania, a
      także dysponowania urządzeniami technicznymi lub oprogramowaniem, o
      których stanowią informacje na stronie internetowej Serwisu. Zamówienie
      Usługę można składać za pośrednictwem strony internetowej Serwisu przez 7
      dni w tygodniu i 24 godziny na dobę, przy użyciu funkcjonalności Serwisu.
      Po wprowadzeniu danych zamawiającego, Użytkownik przechodzi do realizacji
      zamówienia i ma możliwość przekazania Darowizny na rzecz Usługodawcy,
      wybierając sposób płatności. Przekazanie Darowizny ma charakter
      opcjonalny. Złożenie zamówienia może nastąpić poprzez użycie właściwego
      przycisku, jest równoznaczne ze złożeniem Usługodawcy przez Użytkownika
      oferty zawarcia umowy o świadczenie Usługi VOD. §7 Upominki Zamówienia na
      Upominki można składać za pośrednictwem strony internetowej Serwisu przez
      7 dni w tygodniu i 24 godziny na dobę, po zalogowaniu do Konta
      Użytkownika. Po wybraniu Upominków oraz ceny za nie, Użytkownik przechodzi
      do realizacji zamówienia i wprowadza dane o sobie oraz adres dostarczenia.
      Złożenie zamówienia poprzedza otrzymanie poprzez wyświetlenie, informacji
      o łącznej cenie wraz z kosztami pochodnymi. Złożenie zamówienia może
      nastąpić poprzez użycie właściwego przycisku jest równoznaczne ze
      złożeniem Usługodawcy przez Użytkownika oferty zawarcia umowy sprzedaży.
      Przedmiot umowy sprzedaży obejmuje zobowiązanie Usługodawcy do
      przeniesienia na Użytkownika własności Upominków i wydania ich, oraz
      zobowiązanie Użytkownika do odebrania upominków i zapłaty Usługodawcy
      oznaczonej ceny Upominków. Przed wysłaniem formularza zamówienia, poprzez
      zaznaczenie właściwego pola kontrolnego, Użytkownik powinien oświadczyć,
      iż zapoznał się z Regulaminem i akceptuje jego postanowienia.
      Potwierdzenie przyjęcia zamówienia następuje poprzez niezwłoczne
      przesłanie wiadomości e-mail. Wiadomość ta zawiera ustalone przez strony
      warunki zawartej umowy sprzedaży, a także dane wprowadzone przez
      Użytkownika w formularzu zamówienia, w celu umożliwienia wykrycia błędów w
      nich występujących. W przypadku wykrycia takiego błędu, Użytkownik może
      powiadomić Usługodawcę o tym fakcie, poprzez wysłanie wiadomości e-mail ze
      wskazaniem poprawnych danych. Potwierdzenie przyjęcia zamówienia jest
      równoznaczne z przyjęciem przez Usługodawcę oferty zawarcia umowy
      sprzedaży, złożonej przez Użytkownika. Zawierając umowę sprzedaży,
      Usługodawca zobowiązuje się do dostarczenia Użytkownikowi upominków bez
      wad. Wydanie upominków następuje w czasie podanym w opisie upominku i
      odbywa się za pośrednictwem przewoźnika, w dni robocze, pod adres podany
      przez Użytkownika. Szczegółowe informacje w przedmiocie dostępnych metod
      dostarczenia i przewoźników, są opublikowane na stronie internetowej
      Serwisu, a Użytkownik jest o nich informowany w trakcie procesu składania
      zamówienia. Wydanie upominków następuje nie wcześniej, niż po uiszczeniu
      zapłaty przez Użytkownika. Potwierdzenie wydania upominków przewoźnikowi w
      celu ich dostarczenia, może zostać dokonane poprzez przesłanie wiadomości
      e-mail na adres poczty elektronicznej Użytkownika. Zaleca się, aby
      dostarczona przesyłka została zbadana przez użytkownika przy obecności
      przewoźnika. W przypadku stwierdzenia uszkodzenia przesyłki, Użytkownik ma
      prawo żądać sporządzenia właściwego protokołu. §8 Płatności Usługodawca
      umożliwia następujące metody płatności, w tym z tytułu z tytułu
      świadczonych Usług: bezpośredni przelew na rachunek bankowy Usługodawcy,
      przy użyciu zewnętrznego systemu płatności Przelewy24, obsługiwanego przez
      DialCom24 Sp. z o.o. z siedzibą w Poznaniu (60-327) przy ul. Kanclerskiej
      15, wpisaną do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego pod
      numerem KRS: 0000306513, NIP: 7811733852 oraz REGON: 634509164, przy
      użyciu zewnętrznego systemu płatności PayPal, obsługiwanego przez PayPal
      S.à r.l. et Cie, S.C.A. z siedzibą w Luxemburgu. W stosunku do umów
      sprzedaży, termin płatności należności przypada na chwilę wydania
      upominku. Usługodawca może organizować promocje, związane ze świadczonymi
      Usługami. §9 Reklamacje Reklamacje można składać listem lub wiadomością
      e-mail pod adres pocztowy lub elektroniczny, wskazany w Danych
      Kontaktowych Usługodawcy. Zgłoszenie reklamacyjne powinno zawierać opis
      problemu oraz dane identyfikacyjne Użytkownika. Usługodawca rozpoznaje
      reklamacje w terminie 14 dni od daty zgłoszenia. W treści składanej
      reklamacji zaleca się podanie Danych Kontaktowych Użytkownika, które
      posłużą udzieleniu odpowiedzi na reklamację oraz prowadzeniu
      korespondencji z nią związanej. Reklamacje można składać z tytułu rękojmi
      za wady Usług. Konsument posiada możliwość skorzystania z następujących
      pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń:
      złożenie wniosku o rozstrzygnięcie sporu wynikłego z zawartej Umowy do
      stałego polubownego sądu konsumenckiego działającego przy Inspekcji
      Handlowej, złożenie wniosku o wszczęcie postępowania mediacyjnego w
      sprawie polubownego zakończenia sporu między Konsumentem, a Usługodawcą do
      Wojewódzkiego Inspektora Inspekcji Handlowej, skorzystanie z pomocy
      powiatowego lub miejskiego rzecznika konsumentów lub organizacji
      społecznej, do której zadań statutowych należy ochrona konsumentów,
      złożenie skargi za pośrednictwem unijnej platformy internetowej ODR,
      dostępnej pod adresem http://ec.europa.eu/consumers/odr/. §10 Odstąpienie
      od Umowy Prawo odstąpienia od Umowy nie przysługuje Konsumentowi w
      odniesieniu do Umowy o świadczenie Usługi VOD jako usługi dostarczania
      treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli
      spełnianie świadczenia rozpoczęło się za wyraźną zgodą Konsumenta przed
      upływem terminu do odstąpienia od Umowy i po poinformowaniu go przez
      Usługodawcę o utracie prawa odstąpienia od Umowy. W pozostałym zakresie
      Konsument może bez podawania przyczyny odstąpić w terminie 14 dni od
      Umowy. Termin do odstąpienia od Umowy wygasa po upływie 14 dni od dnia
      zawarcia Umowy. Aby skorzystać z prawa odstąpienia od Umowy, Konsumenci
      muszą poinformować Usługodawcę o swojej decyzji o odstąpieniu od
      niniejszej Umowy w drodze jednoznacznego oświadczenia (na przykład pismo
      wysłane pocztą lub pocztą elektroniczną). Konsument może odstąpić od
      Umowy, składając Usługodawcy oświadczenie o odstąpieniu od Umowy.
      Oświadczenie można złożyć na formularzu, którego wzór stanowi załącznik do
      Regulaminu, jednak nie jest to obowiązkowe. Aby zachować termin do
      odstąpienia od Umowy, wystarczy aby Konsument wysłał informację dotyczącą
      wykonania przysługującego prawa odstąpienia od Umowy przed upływem terminu
      do odstąpienia od Umowy. W stosunku do umów sprzedaży, niezwłocznie,
      jednak nie później niż w terminie 14 dni od dnia, w którym Konsument
      odstąpił od umowy, ma on obowiązek zwrócić upominek Usługodawcy lub
      przekazać go osobie upoważnionej przez Usługodawcę. Do zachowania terminu
      wystarczy odesłanie upominku przed jego upływem. Niniejszy przepis nie
      znajduje zastosowania w przypadku, gdy Usługodawca zaproponował, że sam
      odbierze upominek. Konsument ponosi odpowiedzialność za zmniejszenie
      wartości upominku będące wynikiem korzystania z niego w sposób
      wykraczający poza konieczny do stwierdzenia charakteru, cech i
      funkcjonowania upominku. W przypadku otrzymania drogą elektroniczną
      oświadczenia o odstąpieniu przez Konsumenta od Umowy, Usługodawca
      niezwłocznie prześle potwierdzenie jego otrzymania. W przypadku
      odstąpienia od Umowy, uważa się ją za niezawartą. Jeżeli Konsument złożył
      oświadczenie o odstąpieniu od Umowy zanim Usługodawca przyjął jego ofertę,
      oferta przestaje wiązać. §11 Ochrona Danych Osobowych KLAUZULA
      INFORMACYJNA O PRZETWARZANIU DANYCH OSOBOWYCH 8.1 Zgodnie z art. 13 ust. 1
      i ust. 2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27
      kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
      przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
      danych oraz uchylenia dyrektywy 95/46/WE (RODO), informujemy, iż: 8.1.1
      Administratorem danych osobowych Użytkownika jest Fundacja Trinity
      Broadcasting Network Polska, ul. Bohaterów Getta Warszawskiego 7-9, 81-609
      Gdynia, adres e-mail: marketing@tbnpolska.tv. Przetwarzanie danych
      osobowych Użytkownika będzie się odbywać w celu realizacji usług
      oferowanych przez Fundację. 8.1.2 Dane osobowe Użytkownika będą
      przechowywane przez okres niezbędny do realizacji zamówionej usługi oraz
      przez okres przedawnienia roszczeń przysługujących Administratorowi danych
      i w stosunku do niego. 8.1.3 Użytkownik posiada prawo dostępu do treści
      swoich danych osobowych, prawo do ich sprostowania, usunięcia, jak również
      prawo do ograniczenia ich przetwarzania/ prawo do cofnięcia zgody, prawo
      do przenoszenia danych, prawo do wniesienia sprzeciwu wobec przetwarzania
      danych osobowych Użytkownika. 8.1.4 Użytkownikowi przysługuje prawo
      wniesienia skargi do organu nadzorczego, jeśli zdaniem Użytkownika,
      przetwarzanie jego danych osobowych narusza przepisy unijnego
      rozporządzenia RODO. 8.1.5 Podanie przez Użytkownika danych osobowych jest
      wymogiem umownym oraz warunkiem zawarcia umowy z Administratorem. Brak
      podania danych osobowych będzie skutkował odmówieniem wykonania usługi.
      8.1.6 Dane osobowe Użytkownika nie będą przetwarzane w sposób
      zautomatyzowany i nie będą profilowane. 8.2 Przed wysłaniem formularza
      rejestracyjnego, poprzez zaznaczenie właściwego pola formularza
      rejestracyjnego Użytkownik może dobrowolnie oświadczyć, iż wyraża zgodę na
      przetwarzanie jego danych osobowych podanych w formularzu rejestracyjnym
      przez Administratora czyli Fundację Trinity Broadcasting Network Polska,
      ul. Bohaterów Getta Warszawskiego 7-9, 81-609 Gdynia, w celach
      marketingowych, tj. w celu otrzymywania informacji handlowych związanych z
      działalnością Administratora. Podanie danych jest dobrowolne. Podstawą
      przetwarzania danych jest zgoda Użytkownika. Odbiorcami danych może być
      Administrator. Użytkownik ma prawo wycofania zgody w dowolnym momencie.
      Dane osobowe będą przetwarzane do odwołania zgody, a po takim odwołaniu,
      przez okres przedawnienia roszczeń przysługujących Administratorowi danych
      i w stosunku do niego. Użytkownik ma prawo żądania od Administratora
      dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub
      ograniczenia przetwarzania, a także prawo wniesienia skargi do organu
      nadzorczego. 8.3 Oświadczenie, o którym stanowi punkt powyższy, obejmuje
      potwierdzenie przez Użytkownika, iż został poinformowany o treści
      niniejszego przepisu: wskazane cele marketingowe mogą obejmować w
      szczególności przesłanie przez Administratora informacji handlowych przy
      użyciu Danych Kontaktowych Użytkownika. Zgoda, o której stanowi punkt
      powyższy może być cofnięta w każdej chwili, a Użytkownikowi przysługuje
      prawo dostępu do swoich danych osobowych oraz ich poprawiania. §12
      Postanowienia uzupełniające Wszelkie znaki towarowe, elementy graficzne i
      zdjęcia zamieszczone na stronie internetowej Serwisu są przedmiotem praw
      autorskich ich właścicieli. Domena internetowa Serwisu, jego logotypy,
      nazwa i Regulamin stanowią własność prawa autorskiego i przedmiot ochrony
      prawnej. Użytkownik przyjmuje do wiadomości, iż zabronione jest
      dostarczanie przez Użytkownika treści bezprawnych. Usługodawca zobowiązuje
      się do dokładania należytych starań celem umożliwienia prawidłowego
      funkcjonowania Serwisu oraz do udzielania pomocy w rozwiązywaniu problemów
      technicznych, związanych z jego działaniem. Usługodawca zobowiązuje się do
      prowadzania działań zmierzających do ochrony danych znajdujących się w
      Koncie Użytkownika przed nieuprawnionym dostępem i użyciem. Usługodawca
      nie ponosi odpowiedzialności za: przerwy w prawidłowym funkcjonowaniu
      Serwisu oraz nienależyte wykonywanie Usług, spowodowane siłą wyższą, w
      stosunku do Użytkowników niebędących Konsumentami, przerwy w prawidłowym
      funkcjonowaniu Serwisu oraz nienależyte wykonywanie Usług na rzecz
      Użytkowników niebędących Konsumentami, spowodowane czynnościami
      technicznymi lub przyczyną leżącą po stronie podmiotów za pomocą których
      Serwis świadczy Usługi, korzyści utracone przez Użytkownika niebędącego
      Konsumentem, szkody spowodowane naruszeniem przez Użytkownika postanowień
      Regulaminu. §13 Postanowienia końcowe Usługodawca może zbierać informacje,
      celem przechowywania ich lokalnie na urządzeniu Użytkownika, korzystając z
      mechanizmu pamięci przeglądarki przy użyciu plików „cookies”. Dane osobowe
      użytkowników Serwisu przetwarzane są na podstawie zgody osób, których
      dotyczą lub gdy jest to konieczne do realizacji Umowy, gdy osoba której
      dane dotyczą jest jej stroną lub gdy jest to niezbędne do podjęcia działań
      przed zawarciem Umowy na żądanie osoby, której dane dotyczą. Dane osobowe
      są zbierane w celu i zakresie o którym Usługodawca informuje przed
      zebraniem danych lub w chwili ich zbierania. Osobom, których dane zostały
      przez Serwis zebrane, przysługują prawa opisane w Polityce prywatności i
      plików cookies oraz przekazane w sposób czytelny i komunikatywny przed
      zebraniem danych lub w chwili ich zbierania. Prawa o których stanowi punkt
      powyższy, to w zależności od podstaw przetwarzania danych: prawa dostępu
      do treści swoich danych, żądania ich sprostowania, usunięcia, ograniczenia
      przetwarzania, wniesienia sprzeciwu wobec ich przetwarzania, prawo do
      przenoszenia danych, a także prawo wniesienia skargi do organu
      nadzorczego. W przypadku, gdy dane osobowe przetwarzane są na podstawie
      zgody na ich przetwarzanie, może ona zostać cofnięta w dowolnym momencie.
      Cofnięcie zgody nie wpływa na zgodność z prawem wcześniejszego
      przetwarzania danych. Zasady przetwarzania danych osobowych, polityki
      prywatności oraz polityki plików „cookies” uregulowane zostały w
      dokumencie „Polityka prywatności oraz polityka plików cookies”,
      zamieszczonym w pod adresem URL Serwisu, w brzmieniu:
      www.polityka-cookies-i-prywatnosci. Zawierając Umowę o świadczenie Usługi,
      Użytkownik dobrowolnie upoważnia Usługodawcę do kierowania na Dane
      Kontaktowe Użytkownika informacji związanych z Umowami oraz ich
      wykonaniem, a także informacji handlowych w przypadku wyrażenia na to
      odrębnej zgody. Użytkownik niebędący Konsumentem jest zobowiązany do
      informowania Usługodawcy o zmianach Danych Kontaktowych Użytkownika, pod
      rygorem skuteczności ich zastosowania przy doręczeniach. Zmiana Regulaminu
      może nastąpić z przyczyny zmiany przepisów prawa dotyczących świadczeń
      realizowanych przez Usługodawcę, a także zmian technicznych lub
      organizacyjnych dotyczących świadczeń realizowanych przez Usługodawcę.
      Zmiana Regulaminu następuje poprzez publikację jego nowej treści na
      stronie internetowej Serwisu, za uprzednim poinformowaniem o tym fakcie.
      Informację o zmianie Regulaminu umieszcza się w obszarze strony
      internetowej Serwisu nie później niż w terminie 10 dni przed datą
      rozpoczęcia jego obowiązywania oraz wysyła pod Dane Kontaktowe
      Użytkownika, w przypadku gdy strony są związane Umową o charakterze
      ciągłym. Zmiana Regulaminu nie dotyczy Umów zawartych przed datą jego
      zmiany. Postanowienia Regulaminu nie mają na celu wyłączać ani ograniczać
      praw Użytkownika będącego Konsumentem, wynikających z przepisów polskiego
      prawa powszechnie obowiązującego. W przypadku niezgodności postanowień
      Regulaminu z przepisami polskiego prawa powszechnie obowiązującego,
      stosuje się te przepisy. W kwestiach nieuregulowanych Regulaminem
      zastosowanie mają przepisy polskiego prawa powszechnie obowiązującego.
      Regulamin wchodzi w życie w terminie 3 dni od daty jego opublikowania na
      stronie internetowej Serwisu. INFORMACJE DOTYCZĄCE KORZYSTANIA Z PRAWA
      ODSTĄPIENIA OD UMOWY POUCZENIE O ODSTĄPIENIU OD UMOWY Prawo odstąpienia od
      umowy nie przysługuje Konsumentowi w odniesieniu do Umowy o świadczenie
      Usługi VOD jako usługi dostarczania treści cyfrowych, które nie są
      zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło
      się za wyraźną zgodą Konsumenta przed upływem terminu do odstąpienia od
      Umowy i po poinformowaniu go przez Usługodawcę o utracie prawa odstąpienia
      od Umowy. W pozostałym zakresie Konsument może bez podawania przyczyny
      odstąpić w terminie 14 dni od Umowy, bez podania jakiejkolwiek przyczyny,
      z zastrzeżeniem przypadków wskazanych w części „wyłączenie prawa
      odstąpienia od umowy”. Termin do odstąpienia od umowy wygasa po upływie 14
      dni od dnia: w którym weszli Państwo w posiadanie rzeczy lub w którym
      osoba trzecia inna niż przewoźnik i wskazana przez Państwa weszła w
      posiadanie rzeczy – w przypadku umowy zobowiązującej do przeniesienia
      własności rzeczy (np. umowy sprzedaży, umowy dostawy lub umowy o dzieło
      będące rzeczą ruchomą), w którym weszli Państwo w posiadanie ostatniej z
      rzeczy lub w którym osoba trzecia inna niż przewoźnik i wskazana przez
      Państwa weszła w posiadanie ostatniej z rzeczy – w przypadku umowy
      zobowiązującej do przeniesienia własności wielu rzeczy, które dostarczane
      są osobno, w którym weszli Państwo w posiadanie ostatniej partii lub
      części lub w którym osoba trzecia inna niż przewoźnik i wskazana przez
      Państwa weszła w posiadanie ostatniej partii lub części – w przypadku
      umowy zobowiązującej do przeniesienia własności rzeczy dostarczanych
      partiami lub w częściach, w którym weszli Państwo w posiadanie pierwszej z
      rzeczy lub w którym osoba trzecia inna niż przewoźnik i wskazana przez
      Państwa weszła w posiadanie pierwszej z rzeczy – w przypadku umów o
      regularne dostarczanie rzeczy przez czas oznaczony, zawarcia umowy – w
      przypadku umów o świadczenie usług. Aby zachować termin do odstąpienia od
      umowy, wystarczy, aby wysłali Państwo informację dotyczącą wykonania
      przysługującego Państwu prawa odstąpienia od umowy przed upływem terminu
      do odstąpienia od umowy. Aby skorzystać z prawa odstąpienia od umowy,
      muszą Państwo poinformować nas: Trinity Broadcasting Network Polska, ul.
      Bohaterów Getta Warszawskiego 7-9, 81-609 Gdynia, e-mail:
      biuro@tbnpolska.tv, o swojej decyzji o odstąpieniu od niniejszej umowy w
      drodze jednoznacznego oświadczenia (na przykład pismo wysłane pocztą lub
      pocztą elektroniczną). Odstępując od umowy, mogą Państwo skorzystać z
      wzoru formularza odstąpienia od umowy, jednak nie jest to obowiązkowe.
      Wzór formularza znajduje się w załączeniu do regulaminu świadczenia usług
      drogą elektroniczną w ramach Serwisu. SKUTKI ODSTĄPIENIA OD UMOWY W
      przypadku odstąpienia od niniejszej umowy zwracamy Państwu wszystkie
      otrzymane od Państwa płatności, w tym koszty dostarczenia rzeczy (z
      wyjątkiem dodatkowych kosztów wynikających z wybranego przez Państwa
      sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia
      oferowany przez nas), niezwłocznie, a w każdym przypadku nie później niż
      14 dni od dnia, w którym zostaliśmy poinformowani o Państwa decyzji o
      wykonaniu prawa odstąpienia od niniejszej umowy. Zwrotu płatności dokonamy
      przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa
      użyte w pierwotnej transakcji, chyba że wyraźnie zgodziliście się Państwo
      na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych opłat
      w związku z tym zwrotem. Możemy wstrzymać się ze zwrotem płatności do
      czasu otrzymania rzeczy lub do czasu dostarczenia nam dowodu jej
      odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej. Jeżeli
      otrzymali Państwo rzeczy w związku z umową prosimy odesłać lub przekazać
      nam rzecz pod adres Trinity Broadcasting Network Polska, ul. Bohaterów
      Getta Warszawskiego 7-9, 81-609 Gdynia, niezwłocznie, a w każdym razie nie
      później niż 14 dni od dnia, w którym poinformowali nas Państwo o
      odstąpieniu od niniejszej umowy. Termin jest zachowany, jeżeli odeślą
      Państwo rzecz przed upływem terminu 14 dni. Informujemy, że będą Państwo
      musieli ponieść bezpośrednie koszty zwrotu rzeczy. Jeżeli ze względu na
      swój charakter rzecz nie może zostać w zwykły sposób odesłana pocztą, będą
      Państwo musieli ponieść bezpośrednie koszty zwrotu rzeczy. Wysokość tych
      kosztów szacowana jest maksymalnie na kwotę około 20,00 PLN. Odpowiadają
      Państwo tylko za zmniejszenie wartości rzeczy wynikające z korzystania z
      niej w sposób inny niż było to konieczne do stwierdzenia charakteru, cech
      i funkcjonowania rzeczy. WYŁĄCZENIE PRAWA ODSTĄPIENIA OD UMOWY Prawo
      odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa lub na
      odległość nie przysługuje konsumentowi w odniesieniu do umów: o
      świadczenie usług, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną
      zgodą konsumenta, który został poinformowany przed rozpoczęciem
      świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę utraci
      prawo odstąpienia od umowy; o dostarczanie treści cyfrowych, które nie są
      zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło
      się za wyraźną zgodą konsumenta przed upływem terminu do odstąpienia od
      umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa
      odstąpienia od umowy. FORMULARZ ODSTĄPIENIA OD UMOWY (formularz ten należy
      wypełnić i odesłać tylko w przypadku chęci odstąpienia od umowy) –
      Adresat: Trinity Broadcasting Network Polska, ul. Bohaterów Getta
      Warszawskiego 7-9, 81-609 Gdynia – Ja/My(*) niniejszym
      informuję/informujemy(*) o moim/naszym odstąpieniu od umowy o świadczenie
      następującej usługi: Ja/My(*) niniejszym informuję/informujemy(*) o
      moim/naszym odstąpieniu od umowy sprzedaży następujących rzeczy(*) /o
      świadczenie następującej usługi(*): – Data zawarcia umowy(*)/odbioru(*) –
      Imię i nazwisko konsumenta(-ów) – Adres konsumenta(-ów) – Podpis
      konsumenta(-ów) (tylko jeżeli formularz jest przesyłany w wersji
      papierowej) – Data (*) Niepotrzebne skreślić.
    </div>
  );
}

export default RegulaminSerwisu;
