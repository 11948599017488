import React, { useState } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  SlideIn,
  Checkbox,
  Text,
} from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, toggleRegisterModal } from "../../redux/userSlice";
import Cookies from "universal-cookie";
import styles from "./registerModal.module.css";

const cookies = new Cookies();

function RegisterModal() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isNewsletterConsentChecked, setIsNewsletterConsentChecked] =
    useState(true);

  const { isRegisterModalOpen } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const toast = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        {
          firstName,
          lastName,
          email,
          password,
          newsletterConsent: isNewsletterConsentChecked,
        }
      );

      console.log("Register Successful:", response.data);

      toast({
        title: "Konto utworzone.",
        description: "Utworzyliśmy dla Ciebie konto.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      cookies.set("TOKEN", response.data.token, {
        path: "/",
      });

      dispatch(loginUser(response.data.user));
      dispatch(toggleRegisterModal());
    } catch (err) {
      console.error("Register Failed:", err.response?.data);
      setError(err.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      {/* <Button
        variantColor="white"
        variant="ghost"
        onClick={onOpen}
        ml={3}
        color={"#fff"}
      >
        Załóz Konto
      </Button> */}
      <SlideIn in={isRegisterModalOpen}>
        {(slideStyles) => (
          <Modal
            blockScrollOnMount={false}
            isCentered
            isOpen={isRegisterModalOpen}
            onClose={() => dispatch(toggleRegisterModal())}
            size={"xl"}
          >
            <ModalOverlay opacity={slideStyles.opacity} />
            <ModalContent {...slideStyles} borderRadius={20} p={8}>
              <Formik>
                <Form onSubmit={handleSubmit}>
                  <ModalHeader fontWeight={300} fontSize={22}>
                    Załóż konto
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={3}>
                    <FormControl mt={4}>
                      <FormLabel fontWeight={400}>e-mail</FormLabel>
                      <Input
                        borderColor={"#8D8D8D"}
                        borderWidth={"2px"}
                        isRequired
                        type="email"
                        id="email"
                        aria-describedby="email-helper-text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                      />
                    </FormControl>

                    <FormControl mb={3} mt={4}>
                      <FormLabel fontWeight={400} htmlFor="password">
                        hasło
                      </FormLabel>
                      <Input
                        type="password"
                        id="password"
                        borderColor={"#8D8D8D"}
                        borderWidth={"2px"}
                        isRequired
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                      />
                    </FormControl>
                    <Checkbox
                      onChange={() =>
                        setIsNewsletterConsentChecked(
                          !isNewsletterConsentChecked
                        )
                      }
                      isChecked={isNewsletterConsentChecked}
                    >
                      <Text fontSize="sm">
                        Wyrażam zgodę na otrzymywanie newslettera na mój adres
                        e-mail
                      </Text>
                    </Checkbox>
                    {error && <p className={styles.error}>{error}</p>}
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="submit"
                      fontWeight={400}
                      variant="outline"
                      borderColor="#8D8D8D"
                      borderWidth="2px"
                    >
                      Załóż konto
                    </Button>
                  </ModalFooter>
                  <ModalFooter justifyContent="flex-start">
                    <Text fontSize="sm">
                      Założenie konta oznacza akceptację{" "}
                      <Link
                        style={{ textDecoration: "underline" }}
                        to="/regulamin-serwisu"
                      >
                        regulaminu
                      </Link>
                    </Text>
                  </ModalFooter>
                </Form>
              </Formik>
            </ModalContent>
          </Modal>
        )}
      </SlideIn>
    </>
  );
}

export default RegisterModal;
