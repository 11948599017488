import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import ReactGA from "react-ga4";
import { Button } from "@chakra-ui/core";
import "videojs-contrib-ads";
import styles from "./player.module.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const Player = ({ options, onReady, adSrc, liveHls, user }) => {
  const [adTime, setAdTime] = useState(30);
  const [isAdsButtonsVisible, setIsAdsButtonsVisible] = useState(false);
  const [isPlayerInfoVisible, setIsPlayerInfoVisible] = useState(true);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Initialize Video.js player if not already initialized
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      ReactGA.initialize("G-DTPNFED03W");

      const player = videojs(videoElement, options, () => {
        if (onReady) {
          onReady(player);
        }
      });

      playerRef.current = player;
      player.ads(); // Initialize videojs-contrib-ads

      player.on("readyforpreroll", () =>
        handleAdPlayback(player, adSrc, liveHls)
      );
      player.on("play", handlePlayEvent);
      player.on("pause", handlePauseEvent);
      player.on("error", handleErrorEvent);
      player.on("fullscreenchange", handleFullscreenChangeEvent);

      player.trigger("adsready");
    } else {
      // Update player if options change
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, adSrc, liveHls, onReady]);

  // Cleanup on component unmount
  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleAdPlayback = (player, adSrc, liveHls) => {
    player.ads.startLinearAdMode();
    player.src({ src: adSrc, type: "application/x-mpegURL" });
    // player.controls(false);

    player.hlsQualitySelector({
      displayCurrentQuality: true,
    });

    player.one("adplaying", () => {
      player.trigger("ads-ad-started");

      setIsAdsButtonsVisible(true);

      intervalRef.current = setInterval(() => {
        setAdTime((prevState) => {
          if (prevState <= 1) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return 0;
          }
          return prevState - 1;
        });
      }, 1000);
    });

    player.one("adended", () => {
      player.ads.endLinearAdMode();
      player.autoplay(true);
      player.src({ src: liveHls, type: "application/x-mpegURL" });
      player.controls(true);
      toggleControlBar();
      setIsAdsButtonsVisible(false);
    });
  };

  const toggleControlBar = () => {
    const controlBar = document.querySelector(".video-js .vjs-control-bar");
    if (controlBar) {
      controlBar.classList.toggle("custom-content-active");
    }
  };

  const handleAdSkip = (player) => {
    player.ads.endLinearAdMode();
    player.autoplay(true);
    player.src({ src: liveHls, type: "application/x-mpegURL" });
    player.hlsQualitySelector({
      displayCurrentQuality: true,
    });
    player.controls(true);
    toggleControlBar();
    clearInterval(intervalRef.current);
    setIsAdsButtonsVisible(false);
  };

  const handlePlayEvent = () => {
    if (user.subscription === "premium") {
      handleAdSkip(playerRef.current);
    }
    setIsPlayerInfoVisible(false);
    ReactGA.event({
      category: "HLS Stream",
      action: "PLAY_CLICK",
      label: "TBN GO Play button",
    });
  };

  const handlePauseEvent = () => {
    ReactGA.event({
      category: "HLS Stream",
      action: "PAUSE_CLICK",
      label: "TBN GO Pause button",
    });
  };

  const handleErrorEvent = () => {
    ReactGA.event({
      category: "HLS Stream",
      action: "ERROR_MESSAGE",
      label: "TBN GO Error button",
    });
  };

  const handleFullscreenChangeEvent = () => {
    ReactGA.event({
      category: "HLS Stream",
      action: "FULLSCREEN_CLICK",
      label: "TBN GO Fullscreen button",
    });
  };

  return (
    <div className={styles.container} data-vjs-player>
      <div className={styles.player} ref={videoRef}>
        {isPlayerInfoVisible && (
          <div
            onClick={() => playerRef.current.play()}
            className={styles.playerInfo}
          >
            <h1>Oglądaj telewizję na żywo</h1>
            <p>
              Wartościowe programy na wyciągnięcie ręki.<br></br> Włącz
              bezpłatnie lub subskrybuj, by oglądać bez reklam.
            </p>
          </div>
        )}
      </div>
      {isAdsButtonsVisible && (
        <>
          {/* {adTime > 0 ? (
            <Button
              position="absolute"
              variantColor="orange"
              className={styles.skipBtn}
              opacity="0.8"
              borderRadius={0}
            >
              REKLAMA {adTime}
            </Button>
          ) : (
            <Button
              position="absolute"
              className={styles.skipBtn}
              variantColor="green"
              onClick={() => handleAdSkip(playerRef.current)}
            >
              POMIŃ REKLAMĘ
            </Button>
          )} */}

          <div className={styles.planInfo}>
            <div className={styles.planInfoCenter}>
              <p className={styles.planInfoCounter}>
                <strong>
                  Reklama{" "}
                  {
                    playerRef.current.controlBar.remainingTimeDisplay
                      .formattedTime_
                  }{" "}
                </strong>
                Dzięki reklamie oglądasz za darmo
              </p>

              <div className={styles.planInfoBox}>
                <div className={styles.planInfoContent}>
                  <div>
                    <p>
                      Wyłącz reklamy i uzyskaj dostęp do treści premium w VOD.{" "}
                      <br></br> Kup pakiet 9.90 zł na miesiąc lub 100 zł na rok.
                      Bez umów i zobowiązań.
                    </p>
                  </div>
                  <Link to="/oferta">
                    <Button
                      _hover={{ backgroundColor: "D71961" }}
                      backgroundColor="#D71961"
                    >
                      Sprawdź pakiety
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Player;
