import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Link,
  Button,
  useToast,
  AspectRatioBox,
  Image,
  Spinner,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/core";
import Cookies from "universal-cookie";
import { setVideo } from "../../redux/videoSlice";
import axios from "axios";
import Navbar from "../../Navbar";
import { logoutUser, toggleIsUserLoading } from "../../redux/userSlice";
import Footer from "../Footer/Footer";
import styles from "./account.module.css";
import EditPasswordModal from "./editPasswordModal";

const cookies = new Cookies();

function Account() {
  const [likedVideos, setLikedVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.user);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const token = cookies.get("TOKEN");
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useToast();

  if (!token) {
    history.push("/");
  }

  useEffect(() => {
    setIsLoading(true);
    const getVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get-liked-videos`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLikedVideos(response.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    getVideos();
  }, []);

  const handleLogout = async () => {
    dispatch(toggleIsUserLoading());
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/logout`);
      if (res.data) {
        cookies.remove("TOKEN");
        dispatch(logoutUser());
        history.push("/");

        toast({
          title: "Wylogowano pomyślnie.",
          description: "Wylogowałeś się ze swojego konta.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(toggleIsUserLoading());
    }
  };

  const handleCancelSubscription = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/cancel-subscription`,
        { subscriptionId: user.stripeSubscriptionId }
      );
      if (res.data) {
        toast({
          title: "Anulowałeś automatyczne odnawianie swojego planu",
          description: "Nadal masz dostęp Premium do końca subskrybcji.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
        setIsLoading(false);
        history.push("/");
      }
    } catch (err) {
      console.error(err);
      onClose();
      setIsLoading(false);
      history.push("/");
    }
  };

  const handlePosterClick = (videoId) => {
    dispatch(setVideo(videoId));
    history.push(`/vod/${videoId}`);
  };

  if (isLoading) {
    return (
      <>
        <Navbar />
        <div className={styles.spinner}>
          <Spinner thickness="4px" color="#d71961" size="xl" />
        </div>
      </>
    );
  }

  return (
    <div className={styles.main}>
      <Navbar />
      <div className={styles.accountInfo}>
        {likedVideos.length > 0 && (
          <>
            <h3 className={styles.likedTitle}>Ulubione</h3>
            <div className={styles.likedVideos}>
              {likedVideos.map((video) => (
                <AspectRatioBox
                  key={video._id}
                  ratio={16 / 9}
                  className={styles.poster}
                >
                  <Image
                    src={`${
                      process.env.REACT_APP_API_URL +
                      "/uploads/" +
                      video.filename
                    }`}
                    alt="film"
                    objectFit="cover"
                    onClick={() => {
                      handlePosterClick(video._id);
                    }}
                  />
                </AspectRatioBox>
              ))}
            </div>
          </>
        )}

        <div className={styles.btns}>
          {user.cancelAtPeriodEnd && (
            <span>
              Anulowałeś automatyczne odnawianie swojego planu, ale nadal masz
              dostęp Premium do{" "}
              <strong>
                {new Date(
                  parseInt(user.cancelAtPeriodEnd, 10) * 1000
                ).toLocaleDateString()}
              </strong>
            </span>
          )}

          <div>
            {user.subscription === "premium" && !user.cancelAtPeriodEnd && (
              <>
                <Button variantColor="black" mt={4} onClick={onOpen}>
                  Anuluj subskrybcję
                </Button>
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Anulowanie Subskrybcji</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      Czy na pewno checz anulować subskrybcję?
                    </ModalBody>

                    <ModalFooter>
                      <Button variantColor="blue" mr={3} onClick={onClose}>
                        Zamknij
                      </Button>
                      <Button
                        onClick={handleCancelSubscription}
                        variantColor="red"
                      >
                        Tak
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </>
            )}
            {user.subscription !== "premium" && !user.cancelAtPeriodEnd && (
              <>
                <h2>
                  Subskrybuj, aby oglądać bez reklam i otrzymać dostęp do VOD.
                </h2>
                <Button
                  mt={4}
                  onClick={() => history.push("/oferta")}
                  color="#FFF"
                  variant="outline"
                  _hover={{
                    color: "#000",
                    backgroundColor: "lightgrey",
                    border: "none",
                  }}
                >
                  Sprawdź pakiety
                </Button>
              </>
            )}
          </div>

          <Box mt={5}>
            <EditPasswordModal />

            <Button
              ml={5}
              onClick={handleLogout}
              variantColor="red"
              variant="solid"
            >
              Wyloguj
            </Button>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Account;
