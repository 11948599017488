import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import axios from "axios";

const cookies = new Cookies();

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);

  const token = cookies.get("TOKEN");
  let history = useHistory();

  if (!token) {
    history.push("/");
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/getUser`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (res.data._id !== "666416bed725f637c21dac86") {
          history.push("/");
        }
      } catch (err) {
        console.log("Error fetching user data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      getData();
    } else {
      setIsLoading(false);
    }
  }, [token]);

  if (isLoading) {
    return <h1 style={{ fontSize: "100px", color: "#fff" }}>Loading...</h1>;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default ProtectedRoute;
