import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from "@chakra-ui/core";
import Cookies from "universal-cookie";
import axios from "axios";
import { useHistory, Link } from "react-router-dom"; // For redirecting after deletion
import styles from "./manageVideo.module.css";

const cookies = new Cookies();

function ManageVideo() {
  const [videos, setVideos] = useState([]);
  const [videoForDelete, setVideoForDelete] = useState({ title: "" });
  const history = useHistory(); // For redirecting after deletion
  const toast = useToast(); // For displaying notifications

  const token = cookies.get("TOKEN");

  const { isOpen, onOpen, onClose } = useDisclosure(); // For managing delete modal

  useEffect(() => {
    const getVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getVideos`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setVideos(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getVideos();
  }, []);

  const handleDelete = async (videoId) => {
    setVideoForDelete(videos.find((video) => video._id === videoId));
    onOpen(); // Open confirmation modal
  };

  const confirmDelete = async (videoId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/delete-video/${videoId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setVideos(videos.filter((video) => video._id !== videoId)); // Efficiently update state
      toast({
        title: "Video Deleted",
        description: `${videoForDelete.title} has been successfully deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose(); // Close confirmation modal
    } catch (err) {
      console.log(err);
      toast({
        title: "Error Deleting Video",
        description:
          "An error occurred while deleting the video. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <div className={styles.main}>
      <Link className={styles.gobacklink} to="/admin">
        {"<--- Wróć"}
      </Link>

      <Box mt={5}>
        {videos.map((video) => (
          <Box key={video._id} mb={2}>
            <Flex
              borderRadius="4px"
              justifyContent="space-between"
              alignItems="center"
              bg="#fff"
            >
              <Text ml={5} fontSize="lg">
                {video.title}
              </Text>
              <Flex>
                <IconButton
                  icon="edit"
                  variantColor="yellow"
                  onClick={() =>
                    history.push(`/admin/manage-video/edit/${video._id}`)
                  } // Can be used for edit functionality later
                  aria-label="Edit video"
                  mr={2}
                />
                <IconButton
                  icon="delete"
                  variantColor="red"
                  onClick={() => handleDelete(video._id)}
                  aria-label="Delete video"
                />
              </Flex>
            </Flex>
          </Box>
        ))}
      </Box>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete {videoForDelete.title}?
          </ModalBody>
          <Flex justifyContent="flex-end" mt={3}>
            <Box mr={2}>
              <Button variantColor="gray" onClick={onClose}>
                Cancel
              </Button>
            </Box>
            <Button
              variantColor="red"
              onClick={() => confirmDelete(videoForDelete._id)}
            >
              Delete
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ManageVideo;
